import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
const { space2 } = theme();
const style = StyleSheet.create({
    answer: {
        textAlign: "center",
    },
    title: {
        marginBottom: space2,
        textAlign: "center",
    },
});
export { style };

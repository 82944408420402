import { Locale } from "@lookiero/sty-psp-locale";
const deSizes = [
    { size: "EU 34/XS", bust: "80-83", waist: "63-65", hip: "87-90" },
    { size: "EU 36/S", bust: "84-88", waist: "66-69", hip: "91-94" },
    { size: "EU 38/M", bust: "89-94", waist: "70-74", hip: "95-99" },
    { size: "EU 40/L", bust: "95-100", waist: "75-79", hip: "100-105" },
    { size: "EU 42/XL", bust: "101-106", waist: "80-86", hip: "106-112" },
    { size: "EU 44/XXL", bust: "107-112", waist: "87-94", hip: "113-119" },
    { size: "EU 46/3XL", bust: "113-119", waist: "95-102", hip: "120-127" },
    { size: "EU 48/4XL", bust: "120-126", waist: "103-110", hip: "128-135" },
];
const SIZES = {
    [Locale.en_GB]: [
        { size: "6/XS", bust: "32 - 33", waist: "24.5 - 25.5", hip: "34.5 - 35.5" },
        { size: "8/S", bust: "33.5 - 34.5", waist: "26 - 27", hip: "36 - 37" },
        { size: "10/M", bust: "35 - 37", waist: "27.5 - 29", hip: "37.5 - 39" },
        { size: "12/L", bust: "37.5 - 39.5", waist: "29.5 - 31", hip: "39.5 - 41.5" },
        { size: "14/XL", bust: "40 - 42", waist: "31.5 - 34", hip: "42 - 44" },
        { size: "16/XXL", bust: "42.5 - 44", waist: "34.5 - 37", hip: "44.5 - 47" },
        { size: "18/3XL", bust: "44.5 - 47", waist: "37.5 - 40", hip: "47.5 - 50" },
        { size: "20/4XL", bust: "47.5 - 50", waist: "40.5 - 43.5", hip: "50.5 - 53" },
    ],
    [Locale.it_IT]: [
        { size: "38/XS", bust: "80-83", waist: "63-65", hip: "87-90" },
        { size: "40/S", bust: "84-88", waist: "66-69", hip: "91-94" },
        { size: "42/M", bust: "89-94", waist: "70-74", hip: "95-99" },
        { size: "44/L", bust: "95-100", waist: "75-79", hip: "100-105" },
        { size: "46/XL", bust: "101-106", waist: "80-86", hip: "106-112" },
        { size: "48/XXL", bust: "107-112", waist: "87-94", hip: "113-119" },
        { size: "50/3XL", bust: "113-119", waist: "95-102", hip: "120-127" },
        { size: "52/4XL", bust: "120-126", waist: "103-110", hip: "128-135" },
    ],
    [Locale.de_DE]: deSizes,
    [Locale.de_AT]: deSizes,
    [Locale.de_CH]: deSizes,
    [Locale.fr_CH]: deSizes,
    default: [
        { size: "34/XS", bust: "80-83", waist: "63-65", hip: "87-90" },
        { size: "36/S", bust: "84-88", waist: "66-69", hip: "91-94" },
        { size: "38/M", bust: "89-94", waist: "70-74", hip: "95-99" },
        { size: "40/L", bust: "95-100", waist: "75-79", hip: "100-105" },
        { size: "42/XL", bust: "101-106", waist: "80-86", hip: "106-112" },
        { size: "44/XXL", bust: "107-112", waist: "87-94", hip: "113-119" },
        { size: "46/3XL", bust: "113-119", waist: "95-102", hip: "120-127" },
        { size: "48/4XL", bust: "120-126", waist: "103-110", hip: "128-135" },
    ],
};
export { SIZES };

import { StyleSheet } from "react-native";
import { theme } from "../../../theme/theme";
const { space6, space10, layoutMaxWidth } = theme();
const style = StyleSheet.create({
    row: {
        alignItems: "center",
        flexBasis: "auto",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        width: "100%",
    },
    rowLarge: {
        marginHorizontal: "auto",
        maxWidth: layoutMaxWidth,
        paddingHorizontal: undefined,
    },
    rowMedium: {
        paddingHorizontal: space10,
    },
    rowSmall: {
        paddingHorizontal: space6,
    },
});
export { style };

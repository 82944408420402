import { useCallback } from "react";
import { Platform, findNodeHandle } from "react-native";
const isScrollView = (ref) => { var _a; return Boolean((_a = ref.current) === null || _a === void 0 ? void 0 : _a.scrollTo); };
const isKeyboardAwareScrollView = (ref) => { var _a; return Boolean((_a = ref.current) === null || _a === void 0 ? void 0 : _a.scrollToPosition); };
const useScrollTo = ({ scrollRef }) => {
    const measure = useCallback(({ ref, callback }) => {
        if (!ref.current || !(scrollRef === null || scrollRef === void 0 ? void 0 : scrollRef.current)) {
            return;
        }
        if (Platform.OS === "web") {
            callback(ref.current.getBoundingClientRect());
        }
        else {
            ref.current.measureLayout(findNodeHandle(scrollRef.current), (left, top, width, height) => {
                callback({ top, height, left, width });
            });
        }
    }, [scrollRef]);
    const scrollTo = useCallback(({ ref, x, y, animated = true }) => {
        if (!ref.current || !(scrollRef === null || scrollRef === void 0 ? void 0 : scrollRef.current)) {
            return;
        }
        measure({
            ref,
            callback: ({ top, left }) => {
                var _a, _b;
                const scrollX = left + (x || 0);
                const scrollY = top + (y || 0);
                if (isScrollView(scrollRef)) {
                    (_a = scrollRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo({ x: scrollX, y: scrollY, animated });
                }
                else if (isKeyboardAwareScrollView(scrollRef)) {
                    (_b = scrollRef.current) === null || _b === void 0 ? void 0 : _b.scrollToPosition(scrollX, scrollY, animated);
                }
            },
        });
    }, [measure, scrollRef]);
    return scrollTo;
};
export { useScrollTo };

/* eslint-disable react/prop-types */
import React, { useCallback } from "react";
import { OptionContainer, OptionPill } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { useAnswerForId } from "../../behaviours/useAnswers";
import { useAnswersValidation } from "../../behaviours/useAnswersValidation";
import { QuestionItemState, useQuestionItemState } from "../../behaviours/useQuestionItemState";
import { style } from "./RoundedButtonWithIconOnSelectedQuestionItem.style";
const ICON = {
    ["quiz.icon.avoid"]: "avoid",
    ["style_profile.icon.avoid"]: "avoid",
};
const RoundedButtonWithIconOnSelectedQuestionItem = ({ question, questionParentId, }) => {
    const titleText = useI18nMessage({ id: question.translationKey });
    const { answer, onChange } = useAnswerForId({ id: questionParentId });
    const selectedAnswer = answer && answer.find((id) => id === question.id);
    const { validateMaxAnswers } = useAnswersValidation();
    const state = useQuestionItemState({ questionParentId, question });
    const handleOnChange = useCallback(() => {
        onChange({ questionId: question.id, answer: selectedAnswer ? undefined : question.id });
        validateMaxAnswers();
    }, [onChange, question.id, selectedAnswer, validateMaxAnswers]);
    const icon = selectedAnswer ? ICON[question.metadata.icon] : undefined;
    return (React.createElement(OptionContainer, { horizontal: false, option: selectedAnswer, testID: "rounded-button-with-icon-on-selected-question-item", onChange: handleOnChange },
        React.createElement(OptionPill, { icon: icon, name: question.translationKey, style: style.button, testID: question.id, title: titleText, unselected: state === QuestionItemState.UNSELECTED, value: question.id })));
};
export { RoundedButtonWithIconOnSelectedQuestionItem };

import validateDate from "validate-date";
import { imperialHeightToCms, imperialWeightToKgs } from "@lookiero/sty-psp-units";
import { questionHasImperialValue, questionHasMetricValue, questionIsHostDate, questionIsHostHeightImperial, } from "../../../projection/question/question.typeguards";
import { splitDateString } from "../../ui/components/organisms/question/behaviours/dateUtils";
import { splitImperialHeightString, splitImperialWeightString, } from "../../ui/components/organisms/question/behaviours/mapAnswersToImperialSystem";
const validateQuestions = ({ questions, answers }) => {
    let validation = [];
    const validate = (questions) => questions.forEach((question) => {
        const questionValidation = validateQuestion({ question, answer: answers[question.id] });
        if (questionValidation) {
            validation = [...validation, questionValidation];
        }
        validate(question.children || []);
    });
    validate(questions);
    return validation;
};
const validateQuestion = ({ question, answer }) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const metadata = question.metadata;
    let validation = {
        questionId: question.id,
    };
    if (!metadata) {
        return undefined;
    }
    if (metadata.mandatory && (!answer || (answer === null || answer === void 0 ? void 0 : answer.length) === 0)) {
        validation = {
            ...validation,
            mandatory: {
                mandatory: metadata.mandatory,
                mandatoryErrorTranslationKey: metadata.mandatoryErrorTranslationKey,
            },
        };
    }
    if ((metadata.minDate === undefined && metadata.minNumber === undefined) ||
        (answer === null || answer === void 0 ? void 0 : answer[0]) === undefined) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { questionId, ...validationProperties } = validation;
        return Object.keys(validationProperties).length > 0 ? validation : undefined;
    }
    // Date validation
    if (questionIsHostDate(question)) {
        const dateMetadata = metadata;
        if (!isEmptyOrValidDate(answer[0]) ||
            new Date(answer[0]) > new Date(dateMetadata.maxDate) ||
            new Date(answer[0]) < new Date(dateMetadata.minDate)) {
            validation = {
                ...validation,
                value: {
                    minDate: dateMetadata.minDate,
                    maxDate: dateMetadata.maxDate,
                    errorTranslationKey: dateMetadata.errorTranslationKey,
                },
            };
        }
    }
    // Metric validation
    if (questionHasMetricValue(question)) {
        const metricMetadata = metadata;
        const metricAnswer = Number(answer[0]);
        if (isNaN(metricAnswer) || metricAnswer > metricMetadata.maxNumber || metricAnswer < metricMetadata.minNumber) {
            validation = {
                ...validation,
                value: {
                    minNumber: metricMetadata.minNumber,
                    maxNumber: metricMetadata.maxNumber,
                    errorTranslationKey: metricMetadata.errorTranslationKey,
                },
            };
        }
    }
    // Imperial validation
    if (questionHasImperialValue(question)) {
        const imperialMetadata = metadata;
        const { feet, inches } = splitImperialHeightString({ height: answer[0] });
        const { stones, pounds } = splitImperialWeightString({ weight: answer[0] });
        const imperialAnswer = questionIsHostHeightImperial(question)
            ? imperialHeightToCms({ feet: Number(feet || "0"), inches: Number(inches || "0") })
            : imperialWeightToKgs({ stones: Number(stones || "0"), pounds: Number(pounds || "0") });
        if ((feet || inches || stones || pounds) &&
            (isNaN(imperialAnswer) ||
                imperialAnswer > imperialMetadata.maxNumber ||
                imperialAnswer < imperialMetadata.minNumber)) {
            validation = {
                ...validation,
                value: {
                    minNumber: imperialMetadata.minNumber,
                    maxNumber: imperialMetadata.maxNumber,
                    errorTranslationKey: imperialMetadata.errorTranslationKey,
                },
            };
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { questionId, ...validationProperties } = validation;
    return Object.keys(validationProperties).length > 0 ? validation : undefined;
};
const isEmptyOrValidDate = (date) => {
    const { year, month, day } = splitDateString({ date });
    if (
    // some part is missing
    ((year || month || day) && !(year && month && day)) ||
        (year &&
            month &&
            day &&
            // year is not 4 digits
            ((year === null || year === void 0 ? void 0 : year.length) !== 4 ||
                // some part is 0
                Number(year) === 0 ||
                Number(month) === 0 ||
                Number(day) === 0 ||
                // invalid date
                !validateDate(date, "boolean")))) {
        return false;
    }
    return true;
};
const inMemoryValidationView = () => async ({ questions, answers }) => validateQuestions({ questions, answers });
export { inMemoryValidationView };

import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
const { borderRadius3, space4 } = theme();
const STRIKE_OUT_AVOID_BUTTON_WITH_ICON_HEIGHT = 120;
const style = StyleSheet.create({
    button: {
        borderRadius: borderRadius3,
        height: STRIKE_OUT_AVOID_BUTTON_WITH_ICON_HEIGHT,
        justifyContent: "space-between",
        padding: space4,
    },
    text: {
        padding: 0,
    },
});
export { style };

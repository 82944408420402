import React, { forwardRef } from "react";
import { TouchableOpacity } from "react-native";
import { Text } from "@lookiero/aurora";
import { TextInput } from "@lookiero/sty-psp-ui";
import { style } from "./TextInputAction.style";
const ACTIVE_OPACITY = 0.6;
const TextInputAction = forwardRef(({ value, action, error, onPress, ...props }, ref) => {
    const disabled = !value || error;
    return (React.createElement(TextInput, { ...props, ref: ref, blurOnSubmit: false, testID: "text-input-action", value: value, onSubmitEditing: !disabled ? onPress : undefined }, !disabled && (React.createElement(TouchableOpacity, { activeOpacity: ACTIVE_OPACITY, testID: "text-input-action-button", onPress: onPress },
        React.createElement(Text, { level: 3, style: style.action, action: true }, action)))));
});
TextInputAction.displayName = "TextInputAction";
export { TextInputAction };

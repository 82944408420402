import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import invariant from "tiny-invariant";
import { QueryStatus } from "@lookiero/messaging-react";
import { defaultAnswerForId } from "./defaultAnswerForId";
import { filteredQuestionsByAnswers } from "./filteredQuestionsByAnswers";
import { isChildQuestion } from "./isChildQuestion";
import { mapAnswersToImperialSystem } from "./mapAnswersToImperialSystem";
import { mapDefaultAnswers } from "./mapDefaultAnswers";
import { questionById } from "./questionById";
const AnswersContext = createContext(null);
const AnswersProvider = ({ answers = {}, answersStatus, questions, children }) => {
    const [contextAnswers, setContextAnswers] = useState(() => mapAnswersToImperialSystem({ answers, questions }));
    useEffect(() => {
        if (answersStatus !== QueryStatus.SUCCESS) {
            return;
        }
        const mappedAnswers = mapDefaultAnswers({ answers, questions });
        setContextAnswers(mapAnswersToImperialSystem({ answers: mappedAnswers, questions }));
    }, [questions, answers, answersStatus]);
    const onChange = useCallback(({ questionParentId, questionId, answer }) => {
        const parentQuestion = questionById({
            questionId: questionParentId,
            questions,
        });
        if (!parentQuestion) {
            return;
        }
        setContextAnswers((answers) => {
            var _a, _b;
            if (answer) {
                const maxAnswers = ((_a = parentQuestion.metadata) === null || _a === void 0 ? void 0 : _a.maxAnswers) || ((_b = parentQuestion.children) === null || _b === void 0 ? void 0 : _b.length) || 1;
                const updatedAnswer = [...(answers[questionParentId] || []), answer].slice(-maxAnswers);
                return { ...answers, [questionParentId]: updatedAnswer };
            }
            return Object.entries(answers).reduce((acc, [id, prevAnswer]) => {
                if (id !== questionParentId) {
                    return { ...acc, [id]: prevAnswer };
                }
                const updatedAnswer = questionId === questionParentId
                    ? []
                    : (answers[id] || []).filter((prevAnswer) => prevAnswer !== questionId);
                const defaultAnswer = defaultAnswerForId({ id: questionParentId, questions });
                const answer = updatedAnswer.length === 0 && defaultAnswer ? defaultAnswer : updatedAnswer;
                return { ...acc, ...{ [id]: answer } };
            }, {});
        });
    }, [questions]);
    const questionsFilteredByAnswers = useMemo(() => filteredQuestionsByAnswers({ questions, answers: contextAnswers }), [contextAnswers, questions]);
    const value = useMemo(() => ({
        answers: contextAnswers,
        onChange,
        questionsFilteredByAnswers,
    }), [contextAnswers, onChange, questionsFilteredByAnswers]);
    return React.createElement(AnswersContext.Provider, { value: value }, children);
};
const useAnswers = () => {
    const answersContext = useContext(AnswersContext);
    invariant(answersContext, "Your are trying to use the useAnswers hook without wrapping your app with the <AnswersProvider>.");
    return answersContext;
};
const useAnswerForId = ({ id }) => {
    const { answers, onChange } = useAnswers();
    const onAnswerChange = useCallback(({ questionId, answer }) => onChange({ questionParentId: id, questionId, answer }), [id, onChange]);
    const answer = answers[id];
    return { answer, onChange: onAnswerChange };
};
const useAnswersForQuestion = ({ question }) => {
    const { answers } = useAnswers();
    const answersForQuestion = useMemo(() => Object.fromEntries(Object.entries(answers).filter(([questionId]) => isChildQuestion({
        questionId,
        question,
    }))), [answers, question]);
    return { answers: answersForQuestion };
};
export { useAnswers, useAnswerForId, useAnswersForQuestion, AnswersProvider };

import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
const { space8, space6, space1, colorTextMedium } = theme();
const style = StyleSheet.create({
    description: {
        color: colorTextMedium,
        marginBottom: space6,
        width: "100%",
    },
    modal: {
        paddingHorizontal: space8,
        paddingTop: space8,
    },
    title: {
        marginBottom: space1,
        width: "100%",
    },
});
export { style };

import { useCallback } from "react";
import { v4 as uuid } from "uuid";
import { useCommand } from "@lookiero/messaging-react";
import { createToastNotification } from "../../../../domain/notification/command/createToastNotification";
const useCreateToastNotification = ({ contextId, logger }) => {
    const [commandBus, status] = useCommand({ contextId });
    const create = useCallback(async ({ aggregateId, level, bodyI18nKey }) => {
        try {
            await commandBus(createToastNotification({
                aggregateId: aggregateId || uuid(),
                level,
                bodyI18nKey,
            }));
        }
        catch (error) {
            logger.captureException(error);
        }
    }, [commandBus, logger]);
    return [create, status];
};
export { useCreateToastNotification };

import React, { useCallback, useEffect, useState } from "react";
import { View } from "react-native";
import { Icon, Text, COLOR } from "@lookiero/aurora";
import { theme } from "../../../theme/theme";
import { Error } from "../../atoms/error/Error";
import { Field } from "../../atoms/field/Field";
import { style } from "./InputField.style";
const { colorBorderInputError, colorBorderInputFocus, colorBorderInteractive, colorText, colorTextMedium, colorTextError, space6, iconSize, } = theme();
const inputPaddingRightWithIcon = iconSize + space6;
const InputField = ({ label, value, error, hint, style: customStyle, input, icon }) => {
    var _a, _b;
    const [isFocused, setIsFocused] = useState(false);
    const [isFilled, setIsFilled] = useState(Boolean(value));
    useEffect(() => setIsFilled(Boolean(value)), [value]);
    const handleOnBlur = useCallback(() => setIsFocused(false), []);
    const handleOnFocus = useCallback(() => setIsFocused(true), []);
    const color = error ? colorTextError : isFocused ? colorText : colorTextMedium;
    const borderColor = error ? colorBorderInputError : isFocused ? colorBorderInputFocus : colorBorderInteractive;
    const iconColor = error ? COLOR.ICON_ERROR : isFocused ? COLOR.ICON : COLOR.ICON_LIGHT;
    return (React.createElement(View, { style: customStyle === null || customStyle === void 0 ? void 0 : customStyle.inputField, testID: "input-field" },
        React.createElement(View, null,
            React.createElement(Field, { isFocused: isFocused || isFilled, label: label, style: {
                    field: customStyle === null || customStyle === void 0 ? void 0 : customStyle.field,
                    fieldText: [{ color }, customStyle === null || customStyle === void 0 ? void 0 : customStyle.fieldText],
                } }),
            input({
                onBlur: handleOnBlur,
                onFocus: handleOnFocus,
                style: {
                    container: [
                        !!icon && { paddingRight: inputPaddingRightWithIcon },
                        { borderColor },
                        style.inputContainer,
                        (_a = customStyle === null || customStyle === void 0 ? void 0 : customStyle.input) === null || _a === void 0 ? void 0 : _a.container,
                    ],
                    input: (_b = customStyle === null || customStyle === void 0 ? void 0 : customStyle.input) === null || _b === void 0 ? void 0 : _b.input,
                },
            })),
        !!icon && React.createElement(Icon, { color: iconColor, name: icon, style: [style.icon, customStyle === null || customStyle === void 0 ? void 0 : customStyle.icon] }),
        !!error && React.createElement(Error, { error: error, level: 3, style: [style.error, customStyle === null || customStyle === void 0 ? void 0 : customStyle.error] }),
        !!hint && (React.createElement(Text, { level: 3, style: [style.hint, { color }, customStyle === null || customStyle === void 0 ? void 0 : customStyle.hint], detail: true }, hint))));
};
export { InputField };

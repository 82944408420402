import React, { createContext, useContext } from "react";
import invariant from "tiny-invariant";
const KameleoonContext = createContext(null);
const KameleoonProvider = ({ kameleoon, children, }) => (React.createElement(KameleoonContext.Provider, { value: kameleoon }, children));
const useKameleoon = () => {
    const kameleoon = useContext(KameleoonContext);
    invariant(kameleoon !== null, "Your are trying to use the useKameleoon hook without wrapping your app with the <KameleoonProvider>.");
    return kameleoon;
};
export { useKameleoon, KameleoonProvider };

import React, { forwardRef, useCallback } from "react";
import { Platform, TextInput as RNTextInput, View, } from "react-native";
import { theme } from "../../../theme/theme";
import { style } from "./TextInput.style";
const { colorTextMedium } = theme();
const TextInput = forwardRef(({ children, maxLength, value, style: customStyle, autoCapitalize = "sentences", testID = "text-input", placeholder, onChange, onComplete, ...props }, ref) => {
    const handleOnChangeText = useCallback((value) => {
        onChange(value);
        if ((value === null || value === void 0 ? void 0 : value.length) === maxLength) {
            onComplete === null || onComplete === void 0 ? void 0 : onComplete(value);
        }
    }, [maxLength, onChange, onComplete]);
    return (React.createElement(View, { style: [style.container, customStyle === null || customStyle === void 0 ? void 0 : customStyle.container] },
        React.createElement(RNTextInput, { ...props, ref: ref, accessibilityValue: { text: value }, autoCapitalize: autoCapitalize, maxLength: maxLength, placeholder: placeholder, placeholderTextColor: colorTextMedium, testID: testID, value: value ? value : "", style: [
                style.input,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                placeholder && Platform.OS === "ios" && { lineHeight: undefined, paddingBottom: 0 },
                customStyle === null || customStyle === void 0 ? void 0 : customStyle.input,
            ], accessible: true, onChangeText: handleOnChangeText }),
        children));
});
TextInput.displayName = "TextInput";
export { TextInput };

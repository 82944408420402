import { NotificationType } from "../../../domain/notification/model/notification";
const toNotificationProjection = (notificationDto) => notificationDto.type === NotificationType.TOAST
    ? {
        id: notificationDto.aggregateId,
        type: notificationDto.type,
        level: notificationDto.level,
        bodyI18nKey: notificationDto.bodyI18nKey,
    }
    : {
        id: notificationDto.aggregateId,
        type: notificationDto.type,
        titleI18nKey: notificationDto.titleI18nKey,
        bodyI18nKey: notificationDto.bodyI18nKey,
        acceptI18nKey: notificationDto.acceptI18nKey,
    };
export { toNotificationProjection };

import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
const { borderRadiusFull, colorText, space2, space3 } = theme();
const style = StyleSheet.create({
    button: {
        alignItems: "center",
        alignSelf: "stretch",
        borderRadius: borderRadiusFull,
        flexDirection: "row",
        flex: 1,
        justifyContent: "center",
        minHeight: 40,
        minWidth: 56,
        paddingHorizontal: space3,
        paddingVertical: space2,
    },
    text: {
        color: colorText,
        textAlign: "center",
    },
});
export { style };

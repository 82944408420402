import { useQuery as reactQueryUseQuery, useQueryClient, } from "@tanstack/react-query";
import { useCallback, useContext } from "react";
import invariant from "tiny-invariant";
import { getContext } from "./bootstrap";
import { useEvent } from "./useEvent";
export var QueryStatus;
(function (QueryStatus) {
    QueryStatus["IDLE"] = "idle";
    QueryStatus["LOADING"] = "loading";
    QueryStatus["SUCCESS"] = "success";
    QueryStatus["ERROR"] = "error";
})(QueryStatus || (QueryStatus = {}));
export const useQuery = ({ id, query, invalidation, invalidationId, invalidationDelay, invalidationRefetchType = "all", options = {}, contextId, }) => {
    const { messaging: messagingContext, queryClient: queryClientContext } = getContext({ id: contextId });
    const queryClient = useQueryClient({ context: queryClientContext });
    const { queryBus } = useContext(messagingContext);
    invariant(queryBus, "Your are trying to use the useQuery hook without wrapping your app with the <MessagingProvider>.");
    const queryId = id ? getConformantId(id) : getDefaultQueryIdentifier(query);
    const invalidateQueriesId = invalidationId ? getConformantId(invalidationId) : queryId;
    useEvent({
        callback: () => queryClient.invalidateQueries(invalidateQueriesId, { refetchType: invalidationRefetchType }),
        filter: invalidation,
        delay: invalidationDelay,
        contextId,
    });
    const queryFn = useCallback(async ({ signal }) => await queryBus(query, signal), [query, queryBus]);
    const { data, status, fetchStatus } = reactQueryUseQuery(queryId, queryFn, {
        context: queryClientContext,
        ...options,
    });
    return [data, deriveStatus({ status, fetchStatus })];
};
const deriveStatus = ({ status, fetchStatus }) => {
    if ((status === "loading" && fetchStatus !== "idle") || fetchStatus === "fetching") {
        return QueryStatus.LOADING;
    }
    if (status === "success") {
        return QueryStatus.SUCCESS;
    }
    if (status === "error") {
        return QueryStatus.ERROR;
    }
    return QueryStatus.IDLE;
};
const getConformantId = (queryKey) => (typeof queryKey === "string" ? [queryKey] : queryKey);
const getDefaultQueryIdentifier = (query) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { messageId, occurredOn, ...queryPayload } = query;
    return [query.name, JSON.stringify(queryPayload)];
};

import { StyleSheet } from "react-native";
const stylesForColumSize = {
    ["1/4"]: {
        maxWidth: `${100 * (1 / 4)}%`,
    },
    ["1/3"]: {
        maxWidth: `${100 * (1 / 3)}%`,
    },
    ["1/2"]: {
        maxWidth: `${100 * (1 / 2)}%`,
    },
    ["2/3"]: {
        maxWidth: `${100 * (2 / 3)}%`,
    },
    ["1"]: {
        maxWidth: "100%",
    },
};
const style = StyleSheet.create({
    column: {
        width: "100%",
    },
    ...stylesForColumSize,
});
export { style };

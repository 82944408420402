import { imperialHeightToCms, imperialWeightToKgs } from "@lookiero/sty-psp-units";
import { questionIsHostHeightImperial, questionIsHostWeightImperial, } from "../../../../../../projection/question/question.typeguards";
import { splitImperialHeightString, splitImperialWeightString } from "./mapAnswersToImperialSystem";
import { questionById } from "./questionById";
const mapAnswersToInternationalSystem = ({ answers, questions }) => Object.fromEntries(Object.entries(answers).map(([questionId, answer]) => {
    const question = questionById({ questionId, questions });
    if (!question) {
        return [questionId, answer];
    }
    if (questionIsHostHeightImperial(question)) {
        const { feet, inches } = splitImperialHeightString({ height: answer[0] });
        return [questionId, [`${imperialHeightToCms({ feet: Number(feet || "0"), inches: Number(inches || "0") })}`]];
    }
    if (questionIsHostWeightImperial(question)) {
        const { stones, pounds } = splitImperialWeightString({ weight: answer[0] });
        return [
            questionId,
            [`${imperialWeightToKgs({ stones: Number(stones || "0"), pounds: Number(pounds || "0") })}`],
        ];
    }
    return [questionId, answer];
}));
export { mapAnswersToInternationalSystem };

import { Platform, StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
const { space1, space4 } = theme();
const style = StyleSheet.create({
    container: {
        paddingHorizontal: space4,
    },
    inputContainer: {
        flex: 1,
        height: "100%",
        paddingLeft: 0,
        paddingRight: 0,
    },
    lastInputPressable: {
        ...Platform.select({
            web: {
                width: "80%",
            },
            native: {
                flex: 1,
            },
        }),
    },
    pressable: {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        height: "100%",
    },
    separator: {
        marginHorizontal: space1,
        ...Platform.select({
            android: {
                paddingVertical: 0,
            },
        }),
    },
});
export { style };

import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
const { space8 } = theme();
const style = StyleSheet.create({
    column: {
        flex: 1,
    },
    row: {
        flex: 1,
        flexBasis: "auto",
    },
    rowLarge: {
        paddingBottom: space8,
    },
    rowSmall: {
        paddingHorizontal: 0,
    },
});
export { style };

import { useEffect } from "react";
import { useAnswersValidation } from "./useAnswersValidation";
const useQuestionValidationMaxAnswers = ({ questionId, ref }) => {
    const { maxAnswersValidation, resetValidateMaxAnswers, scrollToCta } = useAnswersValidation();
    useEffect(() => {
        if (maxAnswersValidation({ questionId })) {
            scrollToCta(ref);
        }
        return () => {
            resetValidateMaxAnswers();
        };
    }, [questionId, ref, resetValidateMaxAnswers, scrollToCta, maxAnswersValidation]);
};
export { useQuestionValidationMaxAnswers };

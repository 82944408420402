/* eslint-disable react/prop-types */
import React, { useCallback, useMemo, useRef } from "react";
import { View } from "react-native";
import { InputField } from "@lookiero/aurora";
import { useI18nMessage, useIntl } from "@lookiero/i18n-react";
import { I18nMessages } from "../../../../../i18n/i18n";
import { useAnswerForId } from "../../behaviours/useAnswers";
import { useAnswersValidation } from "../../behaviours/useAnswersValidation";
import { useQuestionValidationError } from "../../behaviours/useQuestionValidationError";
const HostSelectQuestionItem = ({ question }) => {
    const { formatMessage } = useIntl();
    const emptyOptionText = useI18nMessage({ id: I18nMessages.HOST_SELECT_EMPTY_OPTION });
    const label = useI18nMessage({ id: question.translationKey });
    const { answer, onChange } = useAnswerForId({ id: question.id });
    const selectedAnswer = answer && answer[0];
    const ref = useRef(null);
    const validationError = useQuestionValidationError({ questionId: question.id, ref });
    const { validateMaxAnswers } = useAnswersValidation();
    const handleOnChange = useCallback((value) => {
        onChange({ questionId: question.id, answer: value });
        validateMaxAnswers();
    }, [onChange, question.id, validateMaxAnswers]);
    const options = useMemo(() => question.children.map((option) => ({ name: formatMessage({ id: option.translationKey }), value: option.id })), [formatMessage, question.children]);
    return (React.createElement(View, { ref: ref, testID: "host-select-question-item" },
        React.createElement(InputField, { emptyOptionText: emptyOptionText, error: Boolean(validationError), icon: "arrow_down", label: label, name: label, options: options, testID: "host-select-question-item-input", value: selectedAnswer, 
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onChange: handleOnChange })));
};
export { HostSelectQuestionItem };

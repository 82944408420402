import React, { useEffect, useState } from "react";
import { useLogger } from "@lookiero/sty-psp-logging";
import { asyncKameleoon } from "../AsyncKameleoon";
import { KameleoonProvider } from "./useKameleoon";
const Kameleoon = ({ siteCode, loader = null, children }) => {
    const logger = useLogger();
    const [kameleoon, setKameleoon] = useState();
    useEffect(() => {
        const loadKameleoon = async () => setKameleoon(await asyncKameleoon({ siteCode, logger }));
        loadKameleoon();
    }, [logger, siteCode]);
    return kameleoon ? React.createElement(KameleoonProvider, { kameleoon: kameleoon }, children) : loader;
};
export { Kameleoon };

import { Layout, QuestionType } from "./question.constants";
const questionHasChildren = (question) => [
    QuestionType.HOST_DEFAULT,
    QuestionType.HOST_SCREEN,
    QuestionType.HOST_SCREEN_WITH_SUMMARY,
    QuestionType.HOST_SELECT,
    QuestionType.HOST_RANK,
].includes(question.type);
const questionHasLayout = (question) => [
    QuestionType.HOST_DEFAULT,
    QuestionType.HOST_SCREEN,
    QuestionType.HOST_SCREEN_WITH_SUMMARY,
    QuestionType.HOST_RANK,
].includes(question.type);
const questionHasDefaultSelection = (question) => [QuestionType.HOST_DEFAULT, QuestionType.HOST_SCREEN, QuestionType.HOST_SCREEN_WITH_SUMMARY].includes(question.type);
const questionIsHostScreen = (question) => [QuestionType.HOST_SCREEN, QuestionType.HOST_SCREEN_WITH_SUMMARY].includes(question.type);
const questionWithTranslationKey = (question) => Boolean(question.translationKey);
const questionIsHostDate = (question) => question.type === QuestionType.HOST_DATE;
const questionIsHostImageUpload = (question) => question.type === QuestionType.HOST_IMAGE_UPLOAD;
const questionHasMetricValue = (question) => [QuestionType.HOST_HEIGHT_METRIC, QuestionType.HOST_WEIGHT_METRIC].includes(question.type);
const questionHasImperialValue = (question) => [QuestionType.HOST_HEIGHT_IMPERIAL, QuestionType.HOST_WEIGHT_IMPERIAL].includes(question.type);
const questionIsHostHeightImperial = (question) => question.type === QuestionType.HOST_HEIGHT_IMPERIAL;
const questionIsHostWeightImperial = (question) => question.type === QuestionType.HOST_WEIGHT_IMPERIAL;
const isValueNumberValidation = (validation) => validation.minNumber !== undefined;
const metadataHasPresentationGridLayout = (layoutMetadata) => layoutMetadata.layout === Layout.GRID;
const metadataHasPresentationHorizonalLayout = (layoutMetadata) => layoutMetadata.layout === Layout.HORIZONTAL_LIST;
export { questionHasChildren, questionHasLayout, questionHasDefaultSelection, questionIsHostScreen, questionIsHostDate, questionIsHostImageUpload, questionHasMetricValue, questionHasImperialValue, questionWithTranslationKey, questionIsHostHeightImperial, questionIsHostWeightImperial, isValueNumberValidation, metadataHasPresentationGridLayout, metadataHasPresentationHorizonalLayout, };

import React from "react";
import { View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { Column, Row, Stack, useScreenSize } from "@lookiero/sty-psp-ui";
import { ToastNotificationItem } from "./ToastNotificationItem";
import { style } from "./ToastNotifications.style";
const NOTIFICATIONS_AUTOHIDE_TIMEOUT = 10000;
const ToastNotifications = ({ notifications = [], autoHideTimeout = NOTIFICATIONS_AUTOHIDE_TIMEOUT, style: customStyle, onRemove, }) => {
    const { top: safeAreaInsetTop } = useSafeAreaInsets();
    const screenSize = useScreenSize();
    const isSmallDevice = screenSize === "S";
    return (React.createElement(View, { pointerEvents: "box-none", style: [style.notifications, customStyle], testID: "toast-notifications" },
        React.createElement(Row, { style: [style.row, isSmallDevice && style.rowSmall, { paddingTop: safeAreaInsetTop }] },
            React.createElement(Column, { size: { M: "1", L: "1/2" } },
                React.createElement(Stack, null, notifications.map((notification) => (React.createElement(ToastNotificationItem, { key: notification.id, autoHideTimeout: autoHideTimeout, notification: notification, style: style.toast, testID: notification.id, onRemove: onRemove }))))))));
};
export { ToastNotifications };

import loadjs from "loadjs";
let instance;
const asyncKameleoon = async ({ siteCode, logger }) => {
    if (instance) {
        return instance;
    }
    const init = async () => {
        if (window.Kameleoon) {
            return;
        }
        try {
            await loadjs(`//${siteCode}.kameleoon.eu/kameleoon.js`, { returnPromise: true });
        }
        catch (error) {
            logger.captureException(error);
        }
    };
    const assignedVariantByExperimentId = ({ experimentId }) => {
        let variation = undefined;
        if (window.Kameleoon && !window.Cypress) {
            window.Kameleoon.API.Core.load();
            const experiment = window.Kameleoon.API.Experiments.getById(experimentId);
            if (experiment && experiment.associatedVariation) {
                variation = experiment.associatedVariation;
            }
        }
        return variation;
    };
    const trackConversion = ({ goalId }) => {
        if (window.Kameleoon) {
            window.Kameleoon.API.Core.load();
            window.Kameleoon.API.Goals.processConversion(goalId);
        }
    };
    instance = {
        assignedVariantByExperimentId,
        trackConversion,
    };
    await init();
    return instance;
};
export { asyncKameleoon };

import React from "react";
import { View } from "react-native";
import { useScreenSize } from "../../../hooks/useScreenSize";
import { style } from "./Column.style";
const Column = ({ children, style: customStyle, size = { M: "2/3", L: "1/3" } }) => {
    const screenSize = useScreenSize();
    const columnSizeForScreenSize = size[screenSize];
    return (React.createElement(View, { style: [style.column, columnSizeForScreenSize && style[columnSizeForScreenSize], customStyle] }, children));
};
export { Column };

import React, { createContext, useContext, useMemo } from "react";
import invariant from "tiny-invariant";
const QuestionItemContext = createContext({});
const QuestionItemProvider = ({ questionItems, children }) => (React.createElement(QuestionItemContext.Provider, { value: questionItems }, children));
const useQuestionItem = ({ type }) => {
    const items = useContext(QuestionItemContext);
    const item = useMemo(() => items[type], [items, type]);
    invariant(items, "Your are trying to use the useQuestionItem hook without wrapping your app with the <QuestionItemProvider>.");
    invariant(item, `The provided QuestionType (${type}) is NOT SUPPORTED`);
    return item;
};
export { useQuestionItem, QuestionItemProvider };

/* eslint-disable react/prop-types */
import React, { useCallback } from "react";
import { COLOR, Icon, Text, Touchable } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { useAnswerForId } from "../../behaviours/useAnswers";
import { useAnswersValidation } from "../../behaviours/useAnswersValidation";
import { QuestionItemState, useQuestionItemState } from "../../behaviours/useQuestionItemState";
import { style } from "./StrikeOutAvoidButtonWithIconQuestionItem.style";
const ICON = {
    ["quiz.icon.dresses"]: "dress_large",
    ["style_profile.icon.dresses"]: "dress_large",
    ["quiz.icon.shirts"]: "shirt_large",
    ["style_profile.icon.shirts"]: "shirt_large",
    ["quiz.icon.t_shirts"]: "t_shirt_round_large",
    ["style_profile.icon.t_shirts"]: "t_shirt_round_large",
    ["quiz.icon.trousers"]: "trousers_large",
    ["style_profile.icon.trousers"]: "trousers_large",
    ["quiz.icon.skirts"]: "skirt_large",
    ["style_profile.icon.skirts"]: "skirt_large",
    ["quiz.icon.outwear"]: "outer_garment_large",
    ["style_profile.icon.outwear"]: "outer_garment_large",
    ["quiz.icon.bags"]: "bag_large",
    ["style_profile.icon.bags"]: "bag_large",
    ["quiz.icon.foulards"]: "scarf_large",
    ["style_profile.icon.foulards"]: "scarf_large",
    ["quiz.icon.footwear"]: "shoes_large",
    ["style_profile.icon.footwear"]: "shoes_large",
};
const ICON_CHECKED = {
    ["quiz.icon.dresses"]: "dress_large_avoid",
    ["style_profile.icon.dresses"]: "dress_large_avoid",
    ["quiz.icon.shirts"]: "shirt_large_avoid",
    ["style_profile.icon.shirts"]: "shirt_large_avoid",
    ["quiz.icon.t_shirts"]: "t_shirt_round_large_avoid",
    ["style_profile.icon.t_shirts"]: "t_shirt_round_large_avoid",
    ["quiz.icon.trousers"]: "trousers_large_avoid",
    ["style_profile.icon.trousers"]: "trousers_large_avoid",
    ["quiz.icon.skirts"]: "skirt_large_avoid",
    ["style_profile.icon.skirts"]: "skirt_large_avoid",
    ["quiz.icon.outwear"]: "outer_garment_large_avoid",
    ["style_profile.icon.outwear"]: "outer_garment_large_avoid",
    ["quiz.icon.bags"]: "bag_large_avoid",
    ["style_profile.icon.bags"]: "bag_large_avoid",
    ["quiz.icon.foulards"]: "scarf_large_avoid",
    ["style_profile.icon.foulards"]: "scarf_large_avoid",
    ["quiz.icon.footwear"]: "shoes_large_avoid",
    ["style_profile.icon.footwear"]: "shoes_large_avoid",
};
const StrikeOutAvoidButtonWithIconQuestionItem = ({ question, questionParentId, }) => {
    const titleText = useI18nMessage({ id: question.translationKey });
    const { answer, onChange } = useAnswerForId({ id: questionParentId });
    const selectedAnswer = answer && answer.find((id) => id === question.id);
    const { validateMaxAnswers } = useAnswersValidation();
    const state = useQuestionItemState({ questionParentId, question });
    const handleOnChange = useCallback(() => {
        onChange({ questionId: question.id, answer: selectedAnswer ? undefined : question.id });
        validateMaxAnswers();
    }, [onChange, question.id, selectedAnswer, validateMaxAnswers]);
    const checked = state === QuestionItemState.SELECTED;
    const backgroundColor = checked ? COLOR.BG_INTERACTIVE_SELECTED : COLOR.BG_INTERACTIVE_ACTIVE;
    const color = checked ? COLOR.ICON : COLOR.ICON_LIGHT;
    const iconName = question.metadata.icon;
    const icon = checked ? ICON_CHECKED[iconName] : ICON[iconName];
    return (React.createElement(Touchable, { backgroundColor: backgroundColor, style: style.button, testID: "strike-out-avoid-button-with-icon-question-item", wide: true, onPress: handleOnChange },
        React.createElement(Icon, { color: color, name: icon }),
        React.createElement(Text, { level: 1, lineThrough: checked, style: style.text, testID: question.id, detail: true }, titleText)));
};
export { StrikeOutAvoidButtonWithIconQuestionItem };

import { Platform, StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
const { space4 } = theme();
const style = StyleSheet.create({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    notifications: {
        left: 0,
        ...Platform.select({
            web: { position: "fixed" },
            ios: { position: "absolute" },
            android: { position: "absolute" },
        }),
        flexDirection: "column",
        top: 0,
        width: "100%",
        zIndex: 20,
    },
    row: {
        flex: 1,
        justifyContent: "center",
    },
    rowSmall: {
        paddingLeft: space4,
        paddingRight: space4,
    },
    toast: {
        marginTop: space4,
    },
});
export { style };

import { questionById } from "./questionById";
import { useAnswers, useAnswerForId } from "./useAnswers";
var QuestionItemState;
(function (QuestionItemState) {
    QuestionItemState["DEFAULT"] = "DEFAULT";
    QuestionItemState["SELECTED"] = "SELECTED";
    QuestionItemState["UNSELECTED"] = "UNSELECTED";
})(QuestionItemState || (QuestionItemState = {}));
const useQuestionItemState = ({ questionParentId, question }) => {
    var _a;
    const { answer } = useAnswerForId({ id: questionParentId });
    const selectedAnswer = answer && answer.find((id) => id === question.id);
    const { questionsFilteredByAnswers: questions } = useAnswers();
    const parentQuestion = questionById({
        questionId: questionParentId,
        questions,
    });
    const unselected = ((_a = parentQuestion.metadata) === null || _a === void 0 ? void 0 : _a.maxAnswers) === 1 && Boolean(answer && answer.length > 0) && !Boolean(selectedAnswer);
    return unselected
        ? QuestionItemState.UNSELECTED
        : selectedAnswer
            ? QuestionItemState.SELECTED
            : QuestionItemState.DEFAULT;
};
export { QuestionItemState, useQuestionItemState };

import { cmsToImperialHeight, kgsToImperialWeight } from "@lookiero/sty-psp-units";
import { questionIsHostHeightImperial, questionIsHostWeightImperial, } from "../../../../../../projection/question/question.typeguards";
import { questionById } from "./questionById";
const joinImperialWeightString = ({ stones = "", pounds = "" }) => `${stones}/${pounds}`;
const joinImperialHeightString = ({ feet = "", inches = "" }) => `${feet}/${inches}`;
const splitImperialWeightString = ({ weight = "/" }) => {
    const [stones = "", pounds = ""] = weight.split("/");
    return { stones, pounds };
};
const splitImperialHeightString = ({ height = "/" }) => {
    const [feet = "", inches = ""] = height.split("/");
    return { feet, inches };
};
const mapAnswersToImperialSystem = ({ answers, questions }) => questions
    ? Object.fromEntries(Object.entries(answers).map(([questionId, answer]) => {
        const question = questionById({ questionId, questions });
        if (!question) {
            return [questionId, answer];
        }
        if (questionIsHostHeightImperial(question)) {
            const { feet, inches } = cmsToImperialHeight({ cms: Number(answer[0] || "") });
            return [questionId, [joinImperialHeightString({ feet: `${feet}`, inches: `${inches}` })]];
        }
        if (questionIsHostWeightImperial(question)) {
            const { stones, pounds } = kgsToImperialWeight({ kgs: Number(answer[0] || "") });
            return [questionId, [joinImperialWeightString({ stones: `${stones}`, pounds: `${pounds}` })]];
        }
        return [questionId, answer];
    }))
    : {};
export { mapAnswersToImperialSystem, joinImperialHeightString, joinImperialWeightString, splitImperialHeightString, splitImperialWeightString, };

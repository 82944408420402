import React, { createContext, useContext, useMemo } from "react";
import invariant from "tiny-invariant";
var QuestionDescriptionId;
(function (QuestionDescriptionId) {
    QuestionDescriptionId["QUIZ_SIZE_GUIDE"] = "quiz-size-guide";
    QuestionDescriptionId["QUIZ_SILHOUETTE_GUIDE"] = "quiz-silhouette-guide";
    QuestionDescriptionId["QUIZ_STYLE_DESCRIPTION"] = "quiz-style-description";
    QuestionDescriptionId["QUIZ_PHOTOS_DESCRIPTION"] = "quiz-photos-description";
    QuestionDescriptionId["STYLE_PROFILE_SIZE_GUIDE"] = "style-profile-size-guide";
    QuestionDescriptionId["STYLE_PROFILE_SILHOUETTE_GUIDE"] = "style-profile-silhouette-guide";
    QuestionDescriptionId["STYLE_PROFILE_STYLE_DESCRIPTION"] = "style-profile-style-description";
    QuestionDescriptionId["STYLE_PROFILE_PHOTOS_DESCRIPTION"] = "style-profile-photos-description";
    QuestionDescriptionId["STYLE_PROFILE_SOCIAL_DESCRIPTION"] = "style-profile-social-description";
})(QuestionDescriptionId || (QuestionDescriptionId = {}));
const QuestionDescriptionContext = createContext(null);
const QuestionDescriptionProvider = ({ children, questionDescriptions, i18nPrefix, portalHostName, onOpenSilhouetteGuide, onOpenSizeGuide, }) => {
    const value = useMemo(() => ({
        questionDescriptions,
        i18nPrefix,
        portalHostName,
        onOpenSilhouetteGuide,
        onOpenSizeGuide,
    }), [i18nPrefix, onOpenSilhouetteGuide, onOpenSizeGuide, portalHostName, questionDescriptions]);
    return React.createElement(QuestionDescriptionContext.Provider, { value: value }, children);
};
const wrapper = (Component) => 
// eslint-disable-next-line react/display-name, react/prop-types
({ i18nPrefix, portalHostName, onOpenSilhouetteGuide, onOpenSizeGuide }) => (React.createElement(Component, { i18nPrefix: i18nPrefix, portalHostName: portalHostName, onOpenSilhouetteGuide: onOpenSilhouetteGuide, onOpenSizeGuide: onOpenSizeGuide }));
const useQuestionDescription = ({ id }) => {
    const { questionDescriptions, i18nPrefix, portalHostName, onOpenSilhouetteGuide, onOpenSizeGuide } = useContext(QuestionDescriptionContext);
    const description = useMemo(() => questionDescriptions[id], [questionDescriptions, id]);
    invariant(questionDescriptions, "Your are trying to use the useQuestionDescription hook without wrapping your app with the <QuestionDescriptionProvider>.");
    invariant(description, `The provided id (${id}) is NOT SUPPORTED`);
    return wrapper(description)({ i18nPrefix, portalHostName, onOpenSilhouetteGuide, onOpenSizeGuide });
};
export { useQuestionDescription, QuestionDescriptionProvider, QuestionDescriptionId };

import { notificationCreated } from "./notificationCreated";
import { notificationRemoved } from "./notificationRemoved";
var NotificationType;
(function (NotificationType) {
    NotificationType["TOAST"] = "TOAST";
    NotificationType["MODAL"] = "MODAL";
})(NotificationType || (NotificationType = {}));
var NotificationLevel;
(function (NotificationLevel) {
    NotificationLevel["INFO"] = "INFO";
    NotificationLevel["ERROR"] = "ERROR";
    NotificationLevel["SUCCESS"] = "SUCCESS";
})(NotificationLevel || (NotificationLevel = {}));
const createToastNotificationHandler = () => async ({ aggregateRoot, command }) => {
    const { aggregateId, type, level, bodyI18nKey } = command;
    return {
        ...aggregateRoot,
        type,
        level,
        bodyI18nKey,
        domainEvents: [notificationCreated({ aggregateId })],
    };
};
const createModalNotificationHandler = () => async ({ aggregateRoot, command }) => {
    const { aggregateId, type, titleI18nKey, bodyI18nKey, acceptI18nKey } = command;
    return {
        ...aggregateRoot,
        type,
        titleI18nKey,
        bodyI18nKey,
        acceptI18nKey,
        domainEvents: [notificationCreated({ aggregateId })],
    };
};
const removeNotificationHandler = () => async ({ aggregateRoot, command }) => {
    const { aggregateId } = command;
    return {
        ...aggregateRoot,
        domainEvents: [notificationRemoved({ aggregateId })],
    };
};
export { NotificationLevel, NotificationType, createToastNotificationHandler, createModalNotificationHandler, removeNotificationHandler, };

import { StyleSheet } from "react-native";
import { theme } from "../../../theme/theme";
const { borderRadius3, borderWidth1, fontInputPaddingRight, inputSize, space1, space4 } = theme();
const style = StyleSheet.create({
    error: {
        marginTop: space1,
    },
    hint: {
        marginTop: space1,
    },
    icon: {
        position: "absolute",
        right: fontInputPaddingRight,
        top: space4,
        zIndex: 4,
    },
    inputContainer: {
        alignItems: "center",
        borderRadius: borderRadius3,
        borderWidth: borderWidth1,
        flexDirection: "row",
        height: inputSize,
    },
});
export { style };

import React, { useCallback, useMemo, useRef, useState } from "react";
import { Platform, View } from "react-native";
import Animated, { LayoutAnimationConfig, FadeIn, FadeOut, LinearTransition, useSharedValue, useAnimatedStyle, withTiming, withDelay, } from "react-native-reanimated";
import { NextBestActionCard } from "../../nextBestActionCard/NextBestActionCard";
import { style } from "./NextBestActionGrid.style";
const NEXT_BEST_ACTION_GRID_MAX_ACTIONS = 4;
const layoutAnimations = {
    entering: FadeIn.delay(600).duration(300),
    exiting: FadeOut.delay(300).duration(300),
    layout: LinearTransition.delay(450).duration(300),
};
// eslint-disable-next-line react/prop-types
const NextBestActionGrid = ({ actions = [], onActionTriggered }) => {
    const gridActions = useMemo(() => actions.slice(0, NEXT_BEST_ACTION_GRID_MAX_ACTIONS), [actions]);
    const initialActions = useRef(actions);
    const initialRender = initialActions.current === actions;
    const renderWithLayoutAnimations = Platform.OS !== "web" || !initialRender;
    // Set all cards' height with the max cards' height.
    const [cardHeight, setCardHeight] = useState();
    const handleOnCardLayout = useCallback(({ nativeEvent: { layout } }) => {
        setCardHeight((height) => Math.max(height || 0, layout.height));
    }, []);
    const gridHeight = useSharedValue(0);
    const handleOnLayout = useCallback(({ nativeEvent: { layout } }) => {
        gridHeight.value = layout.height;
    }, [gridHeight]);
    const gridAnimatedStyle = useAnimatedStyle(() => ({ height: withDelay(450, withTiming(gridHeight.value, { duration: 300 })) }), [gridHeight.value]);
    return (React.createElement(LayoutAnimationConfig, { skipEntering: true, skipExiting: true },
        React.createElement(Animated.View, { style: !initialRender && gridAnimatedStyle },
            React.createElement(View, { style: style.gridContainer, onLayout: handleOnLayout }, gridActions.map((action) => (React.createElement(Animated.View, { key: action.id, ...(renderWithLayoutAnimations ? layoutAnimations : {}), style: style.card },
                React.createElement(View, { style: { height: cardHeight } },
                    React.createElement(NextBestActionCard, { action: action, status: action.status, onActionTriggered: onActionTriggered, onLayout: handleOnCardLayout })))))))));
};
export { NextBestActionGrid, NEXT_BEST_ACTION_GRID_MAX_ACTIONS };

/* eslint-disable react/prop-types */
import React, { useCallback, useMemo, useRef, useState } from "react";
import { View } from "react-native";
import { useI18nMessage } from "@lookiero/i18n-react";
import { InputField } from "@lookiero/sty-psp-ui";
import { useAnswerForId } from "../../behaviours/useAnswers";
import { useAnswersValidation } from "../../behaviours/useAnswersValidation";
import { useQuestionValidationError } from "../../behaviours/useQuestionValidationError";
import { style } from "./HostDateQuestionItem.style";
import { DateInput } from "./components/dateInput/DateInput";
const HostDateQuestionItem = ({ question }) => {
    const label = useI18nMessage({ id: question.translationKey });
    const min = useMemo(() => new Date(question.metadata.minDate), [question.metadata.minDate]);
    const max = useMemo(() => new Date(question.metadata.maxDate), [question.metadata.maxDate]);
    const { answer, onChange } = useAnswerForId({ id: question.id });
    const selectedAnswer = answer && answer[0];
    const ref = useRef(null);
    const [enabledQuestionValidationError, setEnabledQuestionValidationError] = useState(false);
    const validationError = useQuestionValidationError({
        questionId: question.id,
        ref,
        enabled: enabledQuestionValidationError,
    });
    const { validateMaxAnswers } = useAnswersValidation();
    const handleOnChange = useCallback((value) => {
        onChange({ questionId: question.id, answer: value });
        validateMaxAnswers();
    }, [onChange, question.id, validateMaxAnswers]);
    const handleOnBlur = useCallback((onBlur) => () => {
        onBlur();
        setEnabledQuestionValidationError(Boolean(selectedAnswer));
    }, [selectedAnswer]);
    return (React.createElement(View, { ref: ref, style: style.container, testID: "host-date-question-item" },
        React.createElement(InputField, { error: validationError, label: label, value: selectedAnswer, input: ({ onBlur, onFocus, style }) => (React.createElement(DateInput, { max: max, min: min, style: style, value: selectedAnswer, onBlur: handleOnBlur(onBlur), onChange: handleOnChange, onFocus: onFocus })) })));
};
export { HostDateQuestionItem };

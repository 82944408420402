import { useEffect, useState } from "react";
import { MIN_KEYBOARD_HEIGHT } from "./keyboard";
const DEFAULT_VALUE = false;
const useDetectKeyboardOpen = ({ minKeyboardHeight = MIN_KEYBOARD_HEIGHT, defaultValue = DEFAULT_VALUE } = {
    minKeyboardHeight: MIN_KEYBOARD_HEIGHT,
    defaultValue: DEFAULT_VALUE,
}) => {
    const [isKeyboardOpen, setIsKeyboardOpen] = useState(defaultValue);
    useEffect(() => {
        var _a;
        const listener = () => {
            var _a;
            const newState = (((_a = window.visualViewport) === null || _a === void 0 ? void 0 : _a.height) && window.screen.height - minKeyboardHeight > window.visualViewport.height) ||
                false;
            if (isKeyboardOpen !== newState) {
                setIsKeyboardOpen(newState);
            }
        };
        if (typeof visualViewport !== "undefined") {
            (_a = window.visualViewport) === null || _a === void 0 ? void 0 : _a.addEventListener("resize", listener);
        }
        return () => {
            var _a;
            if (typeof visualViewport !== "undefined") {
                (_a = window.visualViewport) === null || _a === void 0 ? void 0 : _a.removeEventListener("resize", listener);
            }
        };
    }, [isKeyboardOpen, minKeyboardHeight]);
    return isKeyboardOpen;
};
export { useDetectKeyboardOpen };

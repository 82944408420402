import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
const { space2 } = theme();
const style = StyleSheet.create({
    container: {
        flex: 1,
    },
    text: {
        marginTop: space2,
    },
});
export { style };

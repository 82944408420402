const isChildQuestion = ({ questionId, question }) => {
    if (questionId === question.id) {
        return true;
    }
    const children = question.children || [];
    for (let index = 0; index < children.length; index++) {
        const isChild = isChildQuestion({ questionId, question: children[index] });
        if (isChild) {
            return true;
        }
    }
    return false;
};
export { isChildQuestion };

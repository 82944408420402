import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
const { colorText, space05 } = theme();
const style = StyleSheet.create({
    action: {
        color: colorText,
        paddingTop: space05,
    },
});
export { style };

import { Portal } from "@gorhom/portal";
import React, { useCallback, useMemo, useState } from "react";
import { Dimensions, Platform, TouchableWithoutFeedback, View, } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import Animated, { useAnimatedStyle, useSharedValue, withDelay, withTiming } from "react-native-reanimated";
import { SafeAreaView, useSafeAreaFrame, useSafeAreaInsets } from "react-native-safe-area-context";
import { useBack } from "@lookiero/aurora";
import { useScreenSize } from "../../../hooks/useScreenSize";
import { theme } from "../../../theme/theme";
import { ButtonIcon } from "../../molecules/buttonIcon/ButtonIcon";
import { Row } from "../row/Row";
import { Sticky } from "../sticky/Sticky";
import { style } from "./Modal.style";
const { space8, space10 } = theme();
const TRANSITION_DELAY = 200;
const TRANSITION_MODAL_SCALE = 0.9;
const MODAL_DESKTOP_VERTICAL_PADDING = space10;
const Modal = ({ children, visible, header, footer, portalHostName, onClose, showCloseButton = false, size = { M: "2/3", L: "1/3" }, style: customStyle, scroll = false, testID = "modal", scrollRef, }) => {
    const screenSize = useScreenSize();
    const isSmallDevice = screenSize === "S";
    const columnSizeForScreenSize = size[screenSize];
    const { height: windowHeight } = Dimensions.get("window");
    const { height: frameHeight } = useSafeAreaFrame();
    const { top: safeAreaInsetTop, bottom: safeAreaInsetBottom } = useSafeAreaInsets();
    const height = Platform.OS === "ios"
        ? frameHeight - safeAreaInsetTop - safeAreaInsetBottom
        : Platform.OS === "android"
            ? frameHeight
            : windowHeight;
    const modalMaxHeight = height - (!isSmallDevice ? MODAL_DESKTOP_VERTICAL_PADDING : 0);
    const translationY = Platform.OS === "android" ? safeAreaInsetTop : 0;
    const handleHardwareBackPress = useCallback(() => {
        if (!visible) {
            return;
        }
        onClose();
        return true; // The event will not be bubbled up
    }, [onClose, visible]);
    useBack(handleHardwareBackPress);
    const [modalHeight, setModalHeight] = useState();
    const handleOnModalLayout = useCallback(({ nativeEvent: { layout: { height }, }, }) => setModalHeight(height), []);
    const [footerHeight, setFooterHeight] = useState(0);
    const handleOnFooterLayout = useCallback(({ height }) => setFooterHeight(height), []);
    const modalOpacity = useSharedValue(visible ? 1 : isSmallDevice ? 1 : 0);
    modalOpacity.value = visible ? 1 : isSmallDevice ? 1 : 0;
    const modalTranslateY = useSharedValue(visible ? translationY : isSmallDevice ? (modalHeight || height) + safeAreaInsetBottom : 0);
    modalTranslateY.value = visible ? translationY : isSmallDevice ? (modalHeight || height) + safeAreaInsetBottom : 0;
    const modalScale = useSharedValue(visible ? 1 : isSmallDevice ? 1 : TRANSITION_MODAL_SCALE);
    modalScale.value = visible ? 1 : isSmallDevice ? 1 : TRANSITION_MODAL_SCALE;
    const modalAnimatedStyle = useAnimatedStyle(() => ({
        opacity: withDelay(visible ? TRANSITION_DELAY : 0, withTiming(modalOpacity.value)),
        transform: [
            { translateY: withDelay(visible ? TRANSITION_DELAY : 0, withTiming(modalTranslateY.value)) },
            { scale: withDelay(visible ? TRANSITION_DELAY : 0, withTiming(modalScale.value)) },
        ],
    }), [modalOpacity.value, modalScale.value, modalTranslateY.value, visible]);
    const overlayOpacity = useSharedValue(visible ? 1 : 0);
    overlayOpacity.value = visible ? 1 : 0;
    const overlayAnimatedStyle = useAnimatedStyle(() => ({
        opacity: withDelay(visible ? 0 : TRANSITION_DELAY, withTiming(overlayOpacity.value)),
    }), [overlayOpacity.value, visible]);
    const ModalContentView = useMemo(() => (scroll ? KeyboardAwareScrollView : View), [scroll]);
    return (React.createElement(Portal, { hostName: portalHostName },
        React.createElement(View, { accessibilityState: { expanded: true }, pointerEvents: visible ? "auto" : "none", style: style.container, testID: testID },
            React.createElement(TouchableWithoutFeedback, { testID: "modal-close-button", onPress: visible ? onClose : undefined },
                React.createElement(Animated.View, { style: [style.overlay, customStyle === null || customStyle === void 0 ? void 0 : customStyle.overlay, overlayAnimatedStyle] })),
            React.createElement(SafeAreaView, { edges: ["right", "top", "left"], pointerEvents: "box-none", style: style.safeArea },
                React.createElement(Row, { pointerEvents: "box-none", style: [style.row, isSmallDevice && style.rowSmall, customStyle === null || customStyle === void 0 ? void 0 : customStyle.row] },
                    React.createElement(Animated.View, { pointerEvents: visible ? "auto" : "none", style: [
                            style.modal,
                            isSmallDevice && style.modalSmall,
                            columnSizeForScreenSize && style[columnSizeForScreenSize],
                            { maxHeight: modalMaxHeight },
                            customStyle === null || customStyle === void 0 ? void 0 : customStyle.modal,
                            modalAnimatedStyle,
                        ], onLayout: handleOnModalLayout },
                        (header || showCloseButton) && (React.createElement(View, { style: [style.header, customStyle === null || customStyle === void 0 ? void 0 : customStyle.header] },
                            React.createElement(View, null, header),
                            showCloseButton && (React.createElement(ButtonIcon, { name: "close", style: { button: [style.closeButton, customStyle === null || customStyle === void 0 ? void 0 : customStyle.closeButton] }, onPress: onClose })))),
                        React.createElement(ModalContentView, { ref: scrollRef, contentInset: { top: 0, bottom: 0 }, keyboardShouldPersistTaps: "handled", showsVerticalScrollIndicator: false },
                            React.createElement(View, { style: {
                                    paddingBottom: translationY + (safeAreaInsetBottom || space8) + (Platform.OS !== "web" ? footerHeight : 0),
                                } }, children)),
                        footer && (React.createElement(Sticky, { style: style.stickyFooter, onLayout: handleOnFooterLayout }, footer))))))));
};
export { Modal };

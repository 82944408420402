var QuestionType;
(function (QuestionType) {
    QuestionType["HOST_DEFAULT"] = "HOST_DEFAULT";
    QuestionType["HOST_SCREEN"] = "HOST_SCREEN";
    QuestionType["HOST_SCREEN_WITH_SUMMARY"] = "HOST_SCREEN_WITH_SUMMARY";
    QuestionType["IMAGE"] = "IMAGE";
    QuestionType["ROUNDED_BUTTON"] = "ROUNDED_BUTTON";
    QuestionType["ROUNDED_BUTTON_WITH_ICON_ON_SELECTED"] = "ROUNDED_BUTTON_WITH_ICON_ON_SELECTED";
    QuestionType["SQUARED_BUTTON"] = "SQUARED_BUTTON";
    QuestionType["HOST_SELECT"] = "HOST_SELECT";
    QuestionType["OPTION"] = "OPTION";
    QuestionType["HOST_HEIGHT_METRIC"] = "HOST_HEIGHT_METRIC";
    QuestionType["HOST_WEIGHT_METRIC"] = "HOST_WEIGHT_METRIC";
    QuestionType["HOST_HEIGHT_IMPERIAL"] = "HOST_HEIGHT_IMPERIAL";
    QuestionType["HOST_WEIGHT_IMPERIAL"] = "HOST_WEIGHT_IMPERIAL";
    QuestionType["HOST_RANK"] = "HOST_RANK";
    QuestionType["CHECKBOX"] = "CHECKBOX";
    QuestionType["HOST_DATE"] = "HOST_DATE";
    QuestionType["HOST_IMAGE_UPLOAD"] = "HOST_IMAGE_UPLOAD";
    QuestionType["SOCIAL_NETWORKS_INPUT"] = "SOCIAL_NETWORKS_INPUT";
    QuestionType["STRIKE_OUT_AVOID_BUTTON_WITH_ICON"] = "STRIKE_OUT_AVOID_BUTTON_WITH_ICON";
    QuestionType["STRIKE_OUT_AVOID_BUTTON"] = "STRIKE_OUT_AVOID_BUTTON";
    QuestionType["HOST_INPUT_PILLS"] = "HOST_INPUT_PILLS";
    QuestionType["STRIKE_OUT_BUTTON_WITH_COLOR"] = "STRIKE_OUT_BUTTON_WITH_COLOR";
    QuestionType["STRIKE_OUT_BUTTON_WITH_PRINT"] = "STRIKE_OUT_BUTTON_WITH_PRINT";
})(QuestionType || (QuestionType = {}));
var Layout;
(function (Layout) {
    Layout["GRID"] = "GRID";
    Layout["VERTICAL_LIST"] = "VERTICAL_LIST";
    Layout["HORIZONTAL_LIST"] = "HORIZONTAL_LIST";
})(Layout || (Layout = {}));
export { QuestionType, Layout };

import { bootstrap as messagingReactBootstrap } from "@lookiero/messaging-react";
import { VIEW_VALIDATION, viewValidationHandler } from "../../projection/validation/viewValidation";
import { inMemoryValidationView } from "../projection/validation/inMemoryValidationView";
const MESSAGING_CONTEXT_ID = "quiz_style_profile_common_ui";
const bootstrap = () => messagingReactBootstrap({ id: MESSAGING_CONTEXT_ID })
    .query(VIEW_VALIDATION, viewValidationHandler, {
    view: inMemoryValidationView(),
})
    .build();
const bootstrapWithBuilder = ({ messaging }) => messaging.query(VIEW_VALIDATION, viewValidationHandler, {
    view: inMemoryValidationView(),
});
export { MESSAGING_CONTEXT_ID, bootstrap, bootstrapWithBuilder };

import React, { useMemo, useRef } from "react";
import { CheckboxQuestionItem, HostDateQuestionItem, HostDefaultQuestionItem, HostHeightImperialQuestionItem, HostHeightMetricQuestionItem, HostRankQuestionItem, HostSelectQuestionItem, HostWeightImperialQuestionItem, HostWeightMetricQuestionItem, ImageQuestionItem, OptionQuestionItem, PhotosDescription, Question, QuestionDescriptionId, QuestionDescriptionProvider, QuestionItemProvider, QuestionType, RoundedButtonQuestionItem, RoundedButtonWithIconOnSelectedQuestionItem, SilhouetteGuide, SizeGuide, SquaredButtonQuestionItem, StrikeOutAvoidButtonWithIconQuestionItem, StrikeOutAvoidButtonQuestionItem, StyleDescription, HostInputPillsQuestionItem, StrikeOutButtonWithColorQuestionItem, StrikeOutButtonWithPrintQuestionItem, } from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { HostImageUploadQuestionItem } from "../../../../components/organisms/question/components/hostImageUploadQuestionItem/HostImageUploadQuestionItem";
import { StyleProfileSocialDescription } from "../../../../components/organisms/question/components/hostScreenDescription/components /styleProfileSocialDescription/StyleProfileSocialDescription";
import { HostScreenQuestionItemHOC } from "../../../../components/organisms/question/components/hostScreenQuestionItem/HostScreenQuestionItem";
import { HostScreenWithSummaryQuestionItemHOC } from "../../../../components/organisms/question/components/hostScreenWithSummaryQuestionItem/HostScreenWithSummaryQuestionItem";
import { SocialNetworksInputQuestionItem } from "../../../../components/organisms/question/components/socialNetworksInputQuestionItem/SocialNetworksInputQuestionItem";
import { PREFIX } from "../../../../i18n/i18n";
const baseQuestionItems = {
    [QuestionType.HOST_DEFAULT]: HostDefaultQuestionItem,
    [QuestionType.HOST_SCREEN]: () => null,
    [QuestionType.HOST_SCREEN_WITH_SUMMARY]: () => null,
    [QuestionType.IMAGE]: ImageQuestionItem,
    [QuestionType.ROUNDED_BUTTON]: RoundedButtonQuestionItem,
    [QuestionType.ROUNDED_BUTTON_WITH_ICON_ON_SELECTED]: RoundedButtonWithIconOnSelectedQuestionItem,
    [QuestionType.SQUARED_BUTTON]: SquaredButtonQuestionItem,
    [QuestionType.HOST_SELECT]: HostSelectQuestionItem,
    [QuestionType.OPTION]: OptionQuestionItem,
    [QuestionType.HOST_HEIGHT_METRIC]: HostHeightMetricQuestionItem,
    [QuestionType.HOST_WEIGHT_METRIC]: HostWeightMetricQuestionItem,
    [QuestionType.HOST_HEIGHT_IMPERIAL]: HostHeightImperialQuestionItem,
    [QuestionType.HOST_WEIGHT_IMPERIAL]: HostWeightImperialQuestionItem,
    [QuestionType.HOST_RANK]: HostRankQuestionItem,
    [QuestionType.CHECKBOX]: CheckboxQuestionItem,
    [QuestionType.HOST_DATE]: HostDateQuestionItem,
    [QuestionType.HOST_IMAGE_UPLOAD]: HostImageUploadQuestionItem,
    [QuestionType.SOCIAL_NETWORKS_INPUT]: SocialNetworksInputQuestionItem,
    [QuestionType.STRIKE_OUT_AVOID_BUTTON_WITH_ICON]: StrikeOutAvoidButtonWithIconQuestionItem,
    [QuestionType.STRIKE_OUT_AVOID_BUTTON]: StrikeOutAvoidButtonQuestionItem,
    [QuestionType.HOST_INPUT_PILLS]: HostInputPillsQuestionItem,
    [QuestionType.STRIKE_OUT_BUTTON_WITH_COLOR]: StrikeOutButtonWithColorQuestionItem,
    [QuestionType.STRIKE_OUT_BUTTON_WITH_PRINT]: StrikeOutButtonWithPrintQuestionItem,
};
const questionDescriptions = {
    [QuestionDescriptionId.STYLE_PROFILE_SIZE_GUIDE]: SizeGuide,
    [QuestionDescriptionId.STYLE_PROFILE_SILHOUETTE_GUIDE]: SilhouetteGuide,
    [QuestionDescriptionId.STYLE_PROFILE_STYLE_DESCRIPTION]: StyleDescription,
    [QuestionDescriptionId.STYLE_PROFILE_PHOTOS_DESCRIPTION]: PhotosDescription,
    [QuestionDescriptionId.STYLE_PROFILE_SOCIAL_DESCRIPTION]: StyleProfileSocialDescription,
};
const QuestionForm = ({ question, onOpenSilhouetteGuide, onOpenSizeGuide, onSuccess }) => {
    const onSuccessRef = useRef(onSuccess);
    onSuccessRef.current = onSuccess;
    /**
     * Memoized questionItems so they are not re-created on each re-render.
     *
     * Passing onSuccessRef will avoid any issues with onSuccess callback memoization.
     */
    const questionItems = useMemo(() => ({
        ...baseQuestionItems,
        [QuestionType.HOST_SCREEN]: HostScreenQuestionItemHOC({ onSuccess: onSuccessRef.current }),
        [QuestionType.HOST_SCREEN_WITH_SUMMARY]: HostScreenWithSummaryQuestionItemHOC({
            onSuccess: onSuccessRef.current,
        }),
    }), []);
    return (React.createElement(QuestionItemProvider, { questionItems: questionItems },
        React.createElement(QuestionDescriptionProvider, { i18nPrefix: PREFIX, portalHostName: "StyleProfile", questionDescriptions: questionDescriptions, onOpenSilhouetteGuide: onOpenSilhouetteGuide, onOpenSizeGuide: onOpenSizeGuide },
            React.createElement(Question, { key: question.id, question: question, questionParentId: "" }))));
};
export { QuestionForm };

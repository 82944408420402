const inMemoryStorage = () => {
    const storage = {};
    const read = async (key) => storage[key] || null;
    const list = async () => Object.values(storage);
    const write = async (key, value) => {
        storage[key] = value;
    };
    const remove = async (key) => {
        delete storage[key];
    };
    return { read, list, write, remove };
};
export { inMemoryStorage };

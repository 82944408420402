import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
const { space3, space4, space6 } = theme();
const style = StyleSheet.create({
    text: {
        marginBottom: space3,
        marginHorizontal: space6,
    },
    textMain: {
        marginBottom: space4,
    },
});
export { style };

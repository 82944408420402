import { storageNotificationsCreate } from "./storageNotificationsCreate";
import { storageNotificationsRemove } from "./storageNotificationsRemove";
const toDomain = ({ aggregateId, type, level, titleI18nKey, bodyI18nKey, acceptI18nKey, }) => ({
    aggregateId,
    type,
    level,
    titleI18nKey,
    bodyI18nKey,
    acceptI18nKey,
    domainEvents: [],
});
const getNotification = ({ storage }) => async (aggregateId) => {
    const notificationDto = await storage.read(aggregateId);
    if (!notificationDto) {
        throw new Error(`Notification not found: ${aggregateId}`);
    }
    return toDomain(notificationDto);
};
const saveNotification = ({ storage }) => async (aggregateRoot) => {
    await Promise.all([
        storageNotificationsCreate({ storage })(aggregateRoot),
        storageNotificationsRemove({ storage })(aggregateRoot),
    ]);
};
export { getNotification, saveNotification };

import { useQuery } from "@lookiero/messaging-react";
import { isNotificationCreated } from "../../../../domain/notification/model/notificationCreated";
import { isNotificationRemoved } from "../../../../domain/notification/model/notificationRemoved";
import { listNotifications } from "../../../../projection/notification/listNotifications";
const shouldInvalidate = (event) => isNotificationCreated(event) || isNotificationRemoved(event);
const useListNotifications = ({ contextId }) => useQuery({
    query: listNotifications(),
    contextId,
    invalidation: shouldInvalidate,
    options: {
        refetchOnMount: "always",
        staleTime: Infinity,
        retry: false,
        refetchOnWindowFocus: false,
    },
});
export { useListNotifications };

import { LOCALE } from "@lookiero/sty-psp-locale";
const translationEndpoint = ({ translationsUrl, translationsApiKey }) => (locale) => `${translationsUrl}/${locale}?key=${translationsApiKey}&no-folding=true`;
const translationExternalEndpoint = ({ translationsUrl, projects }) => (locale) => {
    const projectsQueryParam = projects
        .map(([project, filter]) => `projectFilter=${project}${filter ? `:${filter}` : ""}`)
        .join("&");
    const [language, country] = LOCALE[locale];
    return `${translationsUrl}/${language}/${country}?${projectsQueryParam}`;
};
export { translationEndpoint, translationExternalEndpoint };

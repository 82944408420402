import { useCallback } from "react";
import { useCommand } from "@lookiero/messaging-react";
import { removeNotification } from "../../../../domain/notification/command/removeNotification";
const useRemoveNotification = ({ contextId, logger }) => {
    const [commandBus, status] = useCommand({ contextId });
    const remove = useCallback(async ({ aggregateId }) => {
        try {
            await commandBus(removeNotification({ aggregateId }));
        }
        catch (error) {
            logger.captureException(error);
        }
    }, [commandBus, logger]);
    return [remove, status];
};
export { useRemoveNotification };

import { Platform, StyleSheet } from "react-native";
import { theme } from "../../../theme/theme";
const { fontMap, fontInputSize, fontInputPaddingRight, fontInputLetterSpacing, space05, space1, space3 } = theme();
const style = StyleSheet.create({
    container: {
        ...Platform.select({
            web: {
                paddingHorizontal: fontInputPaddingRight,
            },
            android: {
                paddingLeft: space3,
                paddingRight: fontInputPaddingRight,
            },
            ios: {
                paddingHorizontal: fontInputPaddingRight,
            },
        }),
    },
    input: {
        flex: 1,
        fontFamily: fontMap.Body,
        fontSize: fontInputSize,
        height: "100%",
        letterSpacing: fontInputLetterSpacing,
        minWidth: 0,
        paddingTop: space05,
        ...Platform.select({
            web: {
                outlineStyle: "none",
            },
            android: {
                paddingBottom: space1,
            },
        }),
    },
});
export { style };

import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
const { space2, space3, space6 } = theme();
const style = StyleSheet.create({
    container: {
        flexDirection: "column",
        paddingHorizontal: space6,
    },
    pillGap: {
        marginBottom: space2,
        marginRight: space2,
    },
    pillsContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        marginTop: space3,
    },
});
export { style };

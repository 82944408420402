import { Platform, StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
const { borderRadiusFull, colorBgInteractiveActive, space05, space2, space3 } = theme();
const style = StyleSheet.create({
    button: {
        padding: 0,
    },
    container: {
        alignItems: "center",
        backgroundColor: colorBgInteractiveActive,
        borderRadius: borderRadiusFull,
        flexDirection: "row",
        height: 32,
        maxWidth: "100%",
        paddingHorizontal: space3,
        paddingVertical: space2,
    },
    icon: {
        height: 12,
        width: 12,
    },
    text: {
        marginRight: space2,
        overflow: "hidden",
        ...Platform.select({
            web: {
                paddingTop: space05,
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            },
            native: {
                paddingTop: 0,
            },
        }),
    },
});
export { style };

import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
const { colorTextMedium, space6, space8 } = theme();
const style = StyleSheet.create({
    description: {
        color: colorTextMedium,
        padding: 0,
    },
    modal: {
        paddingHorizontal: space6,
    },
    modalGap: {
        marginTop: space8,
    },
});
export { style };

import { StyleSheet } from "react-native";
import { theme } from "../../../theme/theme";
const { colorTextMedium, colorTextError, colorText, space05 } = theme();
const style = StyleSheet.create({
    error: {
        color: colorTextError,
    },
    unit: {
        color: colorTextMedium,
        paddingTop: space05,
    },
    unitFocused: {
        color: colorText,
    },
});
export { style };

/* eslint-disable react/prop-types */
import React, { useCallback, useRef, useState } from "react";
import { View } from "react-native";
import { useI18nMessage } from "@lookiero/i18n-react";
import { InputField, NumberInput } from "@lookiero/sty-psp-ui";
import { useFocusedInput } from "../../../../../hooks/useFocusedInput";
import { joinImperialWeightString, splitImperialWeightString } from "../../behaviours/mapAnswersToImperialSystem";
import { useAnswerForId } from "../../behaviours/useAnswers";
import { useAnswersValidation } from "../../behaviours/useAnswersValidation";
import { useQuestionValidationError } from "../../behaviours/useQuestionValidationError";
import { style } from "./HostWeightImperialQuestionItem.style";
const HostWeightImperialQuestionItemInput = ({ value, error, style: customStyle, onFocus, onBlur, onChange, onBlurred, }) => {
    const { stones: initialStones, pounds: initialPounds } = splitImperialWeightString({ weight: value });
    const [stones, setStones] = useState(initialStones);
    const [pounds, setPounds] = useState(initialPounds);
    const isFocused = useRef(false);
    const stonesInputRef = useRef(null);
    const poundsInputRef = useRef(null);
    const handleOnStonesChange = useCallback((value) => {
        setStones(value);
        const weight = joinImperialWeightString({ stones: value, pounds });
        onChange(weight === "/" ? undefined : weight);
    }, [pounds, onChange]);
    const handleOnPoundsChange = useCallback((value) => {
        setPounds(value);
        const weight = joinImperialWeightString({ stones, pounds: value });
        onChange(weight === "/" ? undefined : weight);
    }, [stones, onChange]);
    const { focus, blur } = useFocusedInput();
    const stonesFocusedRef = useRef(false);
    const poundsFocusedRef = useRef(false);
    const handleOnStonesFocus = useCallback((onFocus) => () => {
        onFocus();
        focus({ ref: stonesInputRef });
        stonesFocusedRef.current = true;
    }, [focus]);
    const handleOnStonesBlur = useCallback((onBlur) => () => {
        onBlur();
        stonesFocusedRef.current = false;
        setTimeout(() => {
            if (!poundsFocusedRef.current) {
                blur();
                onBlurred();
                if (!stones && !pounds) {
                    isFocused.current = false;
                }
            }
        }, 100);
    }, [blur, stones, pounds, poundsFocusedRef, onBlurred]);
    const handleOnPoundsFocus = useCallback((onFocus) => () => {
        var _a;
        onFocus();
        focus({ ref: poundsInputRef });
        poundsFocusedRef.current = true;
        if (!isFocused.current && !stones) {
            (_a = stonesInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            return;
        }
        isFocused.current = true;
    }, [focus, stones]);
    const handleOnPoundsBlur = useCallback((onBlur) => () => {
        onBlur();
        setTimeout(() => {
            poundsFocusedRef.current = false;
            if (!stonesFocusedRef.current) {
                blur();
                onBlurred();
                if (!stones && !pounds) {
                    isFocused.current = false;
                }
            }
        }, 100);
    }, [blur, stones, pounds, onBlurred]);
    const handleOnStonesComplete = useCallback(() => {
        var _a;
        if (!isFocused.current) {
            return;
        }
        (_a = poundsInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }, []);
    return (React.createElement(View, { style: style.inputContainer },
        React.createElement(NumberInput, { ref: stonesInputRef, error: error, maxLength: 3, style: { container: [customStyle === null || customStyle === void 0 ? void 0 : customStyle.container, style.inputSt], input: customStyle === null || customStyle === void 0 ? void 0 : customStyle.input }, testID: "host-weight-imperial-question-item-stones-input", unit: "st", value: stones, onBlur: handleOnStonesBlur(onBlur), onChange: handleOnStonesChange, onComplete: handleOnStonesComplete, onFocus: handleOnStonesFocus(onFocus) }),
        React.createElement(NumberInput, { ref: poundsInputRef, error: error, maxLength: 3, style: { container: [customStyle === null || customStyle === void 0 ? void 0 : customStyle.container, style.inputLb], input: customStyle === null || customStyle === void 0 ? void 0 : customStyle.input }, testID: "host-weight-imperial-question-item-pounds-input", unit: "lb", value: pounds, onBlur: handleOnPoundsBlur(onBlur), onChange: handleOnPoundsChange, onFocus: handleOnPoundsFocus(onFocus) })));
};
const HostWeightImperialQuestionItem = ({ question }) => {
    const label = useI18nMessage({ id: question.translationKey });
    const { answer, onChange } = useAnswerForId({ id: question.id });
    const selectedAnswer = answer && answer[0];
    const ref = useRef(null);
    const [enabledQuestionValidationError, setEnabledQuestionValidationError] = useState(false);
    const validationError = useQuestionValidationError({
        questionId: question.id,
        ref,
        enabled: enabledQuestionValidationError,
    });
    const { validateMaxAnswers } = useAnswersValidation();
    const handleOnChange = useCallback((value) => {
        onChange({ questionId: question.id, answer: value });
        validateMaxAnswers();
    }, [onChange, question.id, validateMaxAnswers]);
    const handleOnBlurred = useCallback(() => setEnabledQuestionValidationError(Boolean(selectedAnswer)), [selectedAnswer]);
    return (React.createElement(View, { ref: ref, testID: "host-weight-imperial-question-item" },
        React.createElement(InputField, { error: validationError, label: label, value: selectedAnswer, input: ({ onBlur, onFocus, style }) => (React.createElement(HostWeightImperialQuestionItemInput, { error: Boolean(validationError), style: style, value: selectedAnswer, onBlur: onBlur, onBlurred: handleOnBlurred, onChange: handleOnChange, onFocus: onFocus })) })));
};
export { HostWeightImperialQuestionItem };

/* eslint-disable react/prop-types */
import React from "react";
import { Text } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { I18nMessages } from "../../../../../../../i18n/i18n";
import { style } from "./PhotosDescription.style";
const PhotosDescription = ({ i18nPrefix }) => {
    const description = useI18nMessage({ prefix: i18nPrefix, id: I18nMessages.YOU_PHOTOS_SUBTITLE });
    return (React.createElement(Text, { level: 3, style: style.description }, description));
};
export { PhotosDescription };

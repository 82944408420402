import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
const { colorTextMedium, space3, space6 } = theme();
const style = StyleSheet.create({
    container: {
        flexDirection: "column",
        justifyContent: "flex-start",
    },
    rankDescription: {
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: space3,
        paddingHorizontal: space6,
    },
    rankDescriptionText: {
        color: colorTextMedium,
    },
});
export { style };

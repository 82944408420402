var Country;
(function (Country) {
    Country["ES"] = "ES";
    Country["FR"] = "FR";
    Country["GB"] = "GB";
    Country["BE"] = "BE";
    Country["LU"] = "LU";
    Country["IT"] = "IT";
    Country["PT"] = "PT";
    Country["DE"] = "DE";
    Country["AT"] = "AT";
    Country["NL"] = "NL";
    Country["SE"] = "SE";
    Country["CH"] = "CH";
})(Country || (Country = {}));
var Language;
(function (Language) {
    Language["ES"] = "es";
    Language["FR"] = "fr";
    Language["EN"] = "en";
    Language["IT"] = "it";
    Language["PT"] = "pt";
    Language["DE"] = "de";
    Language["NL"] = "nl";
    Language["SV"] = "sv";
})(Language || (Language = {}));
/* eslint-disable @typescript-eslint/naming-convention */
var Locale;
(function (Locale) {
    Locale["es_ES"] = "es-ES";
    Locale["fr_FR"] = "fr-FR";
    Locale["fr_BE"] = "fr-BE";
    Locale["en_GB"] = "en-GB";
    Locale["it_IT"] = "it-IT";
    Locale["pt_PT"] = "pt-PT";
    Locale["de_DE"] = "de-DE";
    Locale["de_AT"] = "de-AT";
    Locale["nl_NL"] = "nl-NL";
    Locale["sv_SE"] = "sv-SE";
    Locale["de_CH"] = "de-CH";
    Locale["fr_CH"] = "fr-CH";
})(Locale || (Locale = {}));
/* eslint-enable @typescript-eslint/naming-convention */
const LOCALE = {
    [Locale.es_ES]: [Language.ES, Country.ES],
    [Locale.fr_FR]: [Language.FR, Country.FR],
    [Locale.fr_BE]: [Language.FR, Country.BE],
    [Locale.en_GB]: [Language.EN, Country.GB],
    [Locale.it_IT]: [Language.IT, Country.IT],
    [Locale.pt_PT]: [Language.PT, Country.PT],
    [Locale.de_DE]: [Language.DE, Country.DE],
    [Locale.de_AT]: [Language.DE, Country.AT],
    [Locale.nl_NL]: [Language.NL, Country.NL],
    [Locale.sv_SE]: [Language.SV, Country.SE],
    [Locale.de_CH]: [Language.DE, Country.CH],
    [Locale.fr_CH]: [Language.FR, Country.CH],
};
const DEFAULT_LANGUAGE = {
    [Country.ES]: Language.ES,
    [Country.FR]: Language.FR,
    [Country.GB]: Language.EN,
    [Country.IT]: Language.IT,
    [Country.PT]: Language.PT,
    [Country.DE]: Language.DE,
    [Country.NL]: Language.NL,
    [Country.SE]: Language.SV,
    [Country.CH]: Language.DE,
    [Country.BE]: Language.FR,
    [Country.LU]: Language.FR,
    [Country.AT]: Language.DE,
};
const isValidLocale = (locale) => Boolean(LOCALE[locale]);
const locale = ({ country, language }) => `${language}-${country}`;
const TLD_COUNTRY = {
    es: Country.ES,
    fr: Country.FR,
    uk: Country.GB,
    be: Country.BE,
    it: Country.IT,
    pt: Country.PT,
    de: Country.DE,
    at: Country.AT,
    nl: Country.NL,
    se: Country.SE,
    ch: Country.CH,
    com: Country.GB,
};
const countryByHostname = (hostname) => {
    const tld = __DEV__ ? "es" : hostname.split(".").slice(-1).join(".");
    return TLD_COUNTRY[tld];
};
export { Country, Language, Locale, locale, isValidLocale, LOCALE, DEFAULT_LANGUAGE, countryByHostname };

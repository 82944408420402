import { questionHasDefaultSelection } from "../../../../../../projection/question/question.typeguards";
import { questionById } from "./questionById";
const defaultAnswerForId = ({ id, questions }) => {
    if (!questions) {
        return undefined;
    }
    const question = questionById({ questionId: id, questions });
    return question && questionHasDefaultSelection(question) && question.metadata.defaultSelectedId
        ? [question.metadata.defaultSelectedId]
        : undefined;
};
export { defaultAnswerForId };

import React from "react";
import { Column, Row, useScreenSize } from "@lookiero/sty-psp-ui";
import { style } from "./Body.style";
const Body = ({ children, style: customStyle, size, testID }) => {
    const screenSize = useScreenSize();
    const rowStyle = screenSize === "S" ? style.rowSmall : style.rowLarge;
    return (React.createElement(Row, { style: [style.row, rowStyle, customStyle === null || customStyle === void 0 ? void 0 : customStyle.row], testID: testID },
        React.createElement(Column, { size: size, style: [style.column, customStyle === null || customStyle === void 0 ? void 0 : customStyle.column] }, children)));
};
export { Body };

import { bootstrap as messagingReactBootstrap } from "@lookiero/messaging-react";
import { CREATE_MODAL_NOTIFICATION } from "../../domain/notification/command/createModalNotification";
import { CREATE_TOAST_NOTIFICATION } from "../../domain/notification/command/createToastNotification";
import { REMOVE_NOTIFICATION } from "../../domain/notification/command/removeNotification";
import { createModalNotificationHandler, createToastNotificationHandler, removeNotificationHandler, } from "../../domain/notification/model/notification";
import { LIST_NOTIFICATIONS, listNotificationsHandler } from "../../projection/notification/listNotifications";
import { getNotification, saveNotification } from "../domain/notification/model/storageNotifications";
import { storageListNotificationsView } from "../projection/notification/storageListNotificationsView";
const NOTIFICATIONS_MESSAGING_CONTEXT_ID = "notifications";
const bootstrap = ({ storage }) => messagingReactBootstrap({ id: NOTIFICATIONS_MESSAGING_CONTEXT_ID })
    .query(LIST_NOTIFICATIONS, listNotificationsHandler, { view: storageListNotificationsView({ storage }) })
    .command(CREATE_TOAST_NOTIFICATION, createToastNotificationHandler)(getNotification, saveNotification, { storage })
    .command(CREATE_MODAL_NOTIFICATION, createModalNotificationHandler)(getNotification, saveNotification, { storage })
    .command(REMOVE_NOTIFICATION, removeNotificationHandler)(getNotification, saveNotification, { storage })
    .build();
const bootstrapWithBuilder = ({ storage, messaging }) => messaging
    .query(LIST_NOTIFICATIONS, listNotificationsHandler, { view: storageListNotificationsView({ storage }) })
    .command(CREATE_TOAST_NOTIFICATION, createToastNotificationHandler)(getNotification, saveNotification, { storage })
    .command(CREATE_MODAL_NOTIFICATION, createModalNotificationHandler)(getNotification, saveNotification, { storage })
    .command(REMOVE_NOTIFICATION, removeNotificationHandler)(getNotification, saveNotification, { storage });
export { NOTIFICATIONS_MESSAGING_CONTEXT_ID, bootstrap, bootstrapWithBuilder };

import { NOTIFICATION_REMOVED } from "../../../../domain/notification/model/notificationRemoved";
const isNotificationRemoved = (event) => event.name === NOTIFICATION_REMOVED;
const storageNotificationsRemove = ({ storage }) => async (aggregateRoot) => {
    const notificationRemoved = aggregateRoot.domainEvents.find(isNotificationRemoved);
    if (!notificationRemoved) {
        return;
    }
    await storage.remove(aggregateRoot.aggregateId);
};
export { storageNotificationsRemove };

import React, { useCallback, useState } from "react";
import { View } from "react-native";
import Animated, { useAnimatedStyle, withTiming } from "react-native-reanimated";
import { Text } from "@lookiero/aurora";
import { theme } from "../../../theme/theme";
import { FIELD_HEIGHT, FIELD_WIDTH, INPUT_HEIGHT, style } from "./Field.style";
const TIMING_OPTIONS = {
    duration: 200,
};
const SCALE = 0.75;
const { space05, space1 } = theme();
const Field = ({ label, isFocused = false, style: customStyle }) => {
    const [containerDimensions, setContainerDimensions] = useState({ width: FIELD_WIDTH, height: FIELD_HEIGHT });
    const handleOnContainerLayout = useCallback(({ nativeEvent: { layout: { height, width }, }, }) => setContainerDimensions({ height, width }), []);
    const [fieldDimensions, setFieldDimensions] = useState({ width: 0, height: INPUT_HEIGHT });
    const handleOnFieldLayout = useCallback(({ nativeEvent: { layout: { width, height }, }, }) => setFieldDimensions({ width, height }), []);
    const fieldAnimatedStyle = useAnimatedStyle(() => ({
        maxWidth: withTiming(isFocused ? containerDimensions.width * (2 - SCALE) : containerDimensions.width, TIMING_OPTIONS),
        transform: [
            {
                translateY: withTiming(isFocused
                    ? -fieldDimensions.height / 2 - space05
                    : (containerDimensions.height - fieldDimensions.height) / 2, TIMING_OPTIONS),
            },
            {
                translateX: withTiming(isFocused ? -(((1 - SCALE) * fieldDimensions.width) / 2 + space1) : 0, TIMING_OPTIONS),
            },
            { scale: withTiming(isFocused ? SCALE : 1, TIMING_OPTIONS) },
        ],
    }), [containerDimensions, fieldDimensions.height, fieldDimensions.width, isFocused]);
    const overlayAnimatedStyle = useAnimatedStyle(() => ({
        opacity: withTiming(isFocused ? 0 : 1, TIMING_OPTIONS),
    }), [isFocused]);
    return (React.createElement(View, { pointerEvents: "none", style: [style.container, customStyle === null || customStyle === void 0 ? void 0 : customStyle.field], onLayout: handleOnContainerLayout },
        React.createElement(Animated.View, { style: [style.field, fieldAnimatedStyle], onLayout: handleOnFieldLayout },
            React.createElement(View, { style: style.fieldTextWrapper },
                React.createElement(Text, { level: 3, numberOfLines: 1, style: [style.fieldText, customStyle === null || customStyle === void 0 ? void 0 : customStyle.fieldText] }, label)),
            React.createElement(View, { style: style.fieldBackground })),
        React.createElement(Animated.View, { style: [style.overlay, overlayAnimatedStyle] })));
};
export { Field };

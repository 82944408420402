import React, { createContext, useCallback, useContext, useEffect, useMemo, useState, } from "react";
import invariant from "tiny-invariant";
import { useScrollTo } from "@lookiero/sty-psp-ui";
import { questionHasChildren } from "../../../../../../projection/question/question.typeguards";
import { useViewValidation } from "../../../../../projection/validation/react/useViewValidation";
import { isChildQuestion } from "./isChildQuestion";
import { questionById } from "./questionById";
import { useAnswers } from "./useAnswers";
const SCROLL_SAFE_TOP_Y = 236;
const MIN_ANSWERS = 2;
const AnswersValidationContext = createContext(null);
const AnswersValidationProvider = ({ scrollRef, children, contextId }) => {
    const { answers, questionsFilteredByAnswers: questions } = useAnswers();
    const [validateMaxAnswers, setValidateMaxAnswers] = useState(false);
    const validateAnswers = useCallback(() => setValidateMaxAnswers(true), []);
    const resetValidateMaxAnswers = useCallback(() => setValidateMaxAnswers(false), []);
    const [validationTriggeredQuestionId, setValidationTriggeredQuestionId] = useState();
    useEffect(() => {
        // Reset the timestamp so it does not trigger the scroll behavior
        setValidationTriggeredQuestionId((prev) => (prev ? { questionId: prev.questionId, timestamp: null } : prev));
    }, [answers]);
    const validateHostScreen = useCallback(({ questionId }) => setValidationTriggeredQuestionId({ questionId, timestamp: Date.now() }), []);
    const [validation] = useViewValidation({ questions, answers, contextId });
    const answersValidation = useMemo(() => {
        if (!validation) {
            return validation;
        }
        if (!validationTriggeredQuestionId) {
            return validation;
        }
        let scrollToErrorTriggered = false;
        return validation.map((v) => {
            const { questionId, timestamp } = validationTriggeredQuestionId;
            const question = questionById({ questionId, questions });
            const triggered = questionId === v.questionId ||
                (question !== undefined &&
                    isChildQuestion({
                        questionId: v.questionId,
                        question,
                    }));
            const triggerScroll = !scrollToErrorTriggered && triggered;
            scrollToErrorTriggered = scrollToErrorTriggered || triggerScroll;
            return triggered
                ? { ...v, ctaTriggered: true, triggerScrollTimestamp: triggerScroll ? timestamp : undefined }
                : v;
        });
    }, [questions, validation, validationTriggeredQuestionId]);
    const maxAnswersValidation = useCallback(({ questionId }) => {
        var _a, _b, _c;
        if (!validateMaxAnswers) {
            return false;
        }
        const question = questionById({ questionId, questions });
        if (!question) {
            return false;
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if ((_a = question.metadata) === null || _a === void 0 ? void 0 : _a.maxAnswers) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return ((_b = answers[question.id]) === null || _b === void 0 ? void 0 : _b.length) === question.metadata.maxAnswers;
        }
        if (questionHasChildren(question)) {
            const mandatoryQuestions = question.children.filter((childQuestion) => {
                const metadata = 
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                childQuestion.metadata || {};
                return Boolean(metadata.mandatory);
            });
            if (mandatoryQuestions.length > 0) {
                return mandatoryQuestions.every((childQuestion) => maxAnswersValidation({ questionId: childQuestion.id }));
            }
            const childrenAnswers = question.children.filter((childQuestion) => Boolean(answers[childQuestion.id]));
            return childrenAnswers.length >= MIN_ANSWERS || (((_c = answers[question.id]) === null || _c === void 0 ? void 0 : _c.length) || 0) >= MIN_ANSWERS;
        }
        return false;
    }, [questions, answers, validateMaxAnswers]);
    const scrollTo = useScrollTo({ scrollRef });
    const scrollToCta = useCallback((ref) => scrollTo({ ref }), [scrollTo]);
    const scrollToError = useCallback((ref) => scrollTo({ ref, y: SCROLL_SAFE_TOP_Y }), [scrollTo]);
    const value = useMemo(() => ({
        validation: answersValidation,
        validateHostScreen,
        validateMaxAnswers: validateAnswers,
        resetValidateMaxAnswers,
        maxAnswersValidation,
        scrollToError,
        scrollToCta,
    }), [
        answersValidation,
        validateHostScreen,
        validateAnswers,
        resetValidateMaxAnswers,
        maxAnswersValidation,
        scrollToError,
        scrollToCta,
    ]);
    return React.createElement(AnswersValidationContext.Provider, { value: value }, children);
};
const useAnswersValidation = () => {
    const answersValidationContext = useContext(AnswersValidationContext);
    invariant(answersValidationContext, "Your are trying to use the useAnswersValidation hook without wrapping your app with the <AnswersValidationProvider>.");
    return answersValidationContext;
};
export { AnswersValidationProvider, useAnswersValidation };

import { questionHasChildren } from "../../../../../../projection/question/question.typeguards";
const isIdIncludedInAnswers = ({ answerId, answers }) => Object.values(answers).some((answer) => answer.find((a) => answerId.includes(a)));
const filteredQuestionsByAnswers = ({ questions, answers }) => questions.reduce((acc, question) => {
    var _a;
    let newItem = { ...question };
    if (questionHasChildren(newItem)) {
        newItem = {
            ...newItem,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            children: filteredQuestionsByAnswers({ questions: newItem.children, answers }),
        };
    }
    if (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    !((_a = question.metadata) === null || _a === void 0 ? void 0 : _a.hideCondition) ||
        !isIdIncludedInAnswers({
            answerId: 
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            question.metadata.hideCondition,
            answers,
        })) {
        acc = [...acc, newItem];
    }
    return acc;
}, []);
export { filteredQuestionsByAnswers };

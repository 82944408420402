/* eslint-disable react/prop-types */
import React, { useCallback } from "react";
import { View } from "react-native";
import { useI18nMessage } from "@lookiero/i18n-react";
import { RoundedButton } from "../../../../atom/roundedButton/RoundedButton";
import { useAnswerForId } from "../../behaviours/useAnswers";
import { useAnswersValidation } from "../../behaviours/useAnswersValidation";
import { QuestionItemState, useQuestionItemState } from "../../behaviours/useQuestionItemState";
import { style } from "./StrikeOutAvoidButtonQuestionItem.style";
const StrikeOutAvoidButtonQuestionItem = ({ question, questionParentId, }) => {
    const titleText = useI18nMessage({ id: question.translationKey });
    const { answer, onChange } = useAnswerForId({ id: questionParentId });
    const selectedAnswer = answer && answer.find((id) => id === question.id);
    const { validateMaxAnswers } = useAnswersValidation();
    const state = useQuestionItemState({ questionParentId, question });
    const handleOnChange = useCallback(() => {
        onChange({ questionId: question.id, answer: selectedAnswer ? undefined : question.id });
        validateMaxAnswers();
    }, [onChange, question.id, selectedAnswer, validateMaxAnswers]);
    const checked = state === QuestionItemState.SELECTED;
    return (React.createElement(View, { style: style.container, testID: "strike-out-avoid-button-question-item" },
        React.createElement(RoundedButton, { checked: checked, lineThrough: checked, testID: question.id, title: titleText, onPress: handleOnChange })));
};
export { StrikeOutAvoidButtonQuestionItem };

import { useQuery } from "@lookiero/messaging-react";
import { viewValidation } from "../../../../projection/validation/viewValidation";
const useViewValidation = ({ questions, answers, contextId }) => useQuery({
    query: viewValidation({
        questions: questions,
        answers: answers,
    }),
    contextId,
    options: {
        staleTime: Infinity,
        retry: false,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        enabled: questions !== undefined && answers !== undefined,
    },
});
export { useViewValidation };

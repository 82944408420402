import { I18nMessages } from "../../../../../../../i18n/i18n";
var Silhouette;
(function (Silhouette) {
    Silhouette["TRIANGLE"] = "TRIANGLE";
    Silhouette["OVAL"] = "OVAL";
    Silhouette["INVERTED_TRIANGLE"] = "INVERTED_TRIANGLE";
    Silhouette["RECTANGLE"] = "RECTANGLE";
    Silhouette["HOURGLASS"] = "HOURGLASS";
    Silhouette["DIAMOND"] = "DIAMOND";
})(Silhouette || (Silhouette = {}));
const SILHOUETTE_GUIDE = {
    [Silhouette.TRIANGLE]: {
        icon: "shape_triangle",
        title: I18nMessages.SILHOUETTE_SILHOUETTE_TRIANGLE_TITLE,
        description: I18nMessages.SILHOUETTE_SILHOUETTE_TRIANGLE_TEXT,
    },
    [Silhouette.OVAL]: {
        icon: "shape_oval",
        title: I18nMessages.SILHOUETTE_SILHOUETTE_OVAL_TITLE,
        description: I18nMessages.SILHOUETTE_SILHOUETTE_OVAL_TEXT,
    },
    [Silhouette.INVERTED_TRIANGLE]: {
        icon: "shape_wedge",
        title: I18nMessages.SILHOUETTE_SILHOUETTE_INVERTED_TRIANGLE_TITLE,
        description: I18nMessages.SILHOUETTE_SILHOUETTE_INVERTED_TRIANGLE_TEXT,
    },
    [Silhouette.RECTANGLE]: {
        icon: "shape_rectangle",
        title: I18nMessages.SILHOUETTE_SILHOUETTE_RECTANGLE_TITLE,
        description: I18nMessages.SILHOUETTE_SILHOUETTE_RECTANGLE_TEXT,
    },
    [Silhouette.HOURGLASS]: {
        icon: "hourglass",
        title: I18nMessages.SILHOUETTE_SILHOUETTE_HOURGLASS_TITLE,
        description: I18nMessages.SILHOUETTE_SILHOUETTE_HOURGLASS_TEXT,
    },
    [Silhouette.DIAMOND]: {
        icon: "shape_diamond",
        title: I18nMessages.SILHOUETTE_SILHOUETTE_DIAMOND_TITLE,
        description: I18nMessages.SILHOUETTE_SILHOUETTE_DIAMOND_TEXT,
    },
};
export { SILHOUETTE_GUIDE };

import { StyleSheet } from "react-native";
const style = StyleSheet.create({
    inputContainer: {
        flexDirection: "row",
    },
    inputLb: {
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 0,
        flex: 1,
    },
    inputSt: {
        borderBottomRightRadius: 0,
        borderRightWidth: 0,
        borderTopRightRadius: 0,
        flex: 1,
    },
});
export { style };

import React, { memo } from "react";
import { questionHasChildren } from "../../../../../projection/question/question.typeguards";
import { useQuestionItem } from "./behaviours/useQuestionItem";
import { QuestionLayout } from "./components/questionLayout/QuestionLayout";
const Question = ({ question, questionParentId, level = 0 }) => {
    const Item = useQuestionItem({ type: question.type });
    return (React.createElement(Item, { level: level, question: question, questionParentId: questionParentId }, questionHasChildren(question) && React.createElement(QuestionLayout, { level: level, question: question })));
};
const memorizedQuestion = memo(Question);
export { memorizedQuestion as Question };

import React, { useCallback, useState } from "react";
import { View } from "react-native";
var AspectRatioViewResizeDirection;
(function (AspectRatioViewResizeDirection) {
    AspectRatioViewResizeDirection["VERTICAL"] = "VERTICAL";
    AspectRatioViewResizeDirection["HORIZONTAL"] = "HORIZONTAL";
})(AspectRatioViewResizeDirection || (AspectRatioViewResizeDirection = {}));
const AspectRatioView = ({ aspectRatio = 1, resizeDirection = AspectRatioViewResizeDirection.HORIZONTAL, children, }) => {
    const [{ width, height }, setDimension] = useState({});
    const handleOnLayout = useCallback(({ nativeEvent: { layout } }) => setDimension(resizeDirection === AspectRatioViewResizeDirection.HORIZONTAL
        ? { width: layout.width, height: layout.width * aspectRatio }
        : { width: layout.height * aspectRatio, height: layout.height }), [aspectRatio, resizeDirection]);
    return (React.createElement(View, { onLayout: handleOnLayout },
        React.createElement(View, { style: { width, height } }, children)));
};
export { AspectRatioView, AspectRatioViewResizeDirection };

import { isErrorFunction } from "./httpClient";
const fetchHttpPost = ({ apiUrl, getAuthToken, device, version }) => async ({ endpoint, body, signal, result }) => {
    const response = await fetch(`${apiUrl()}${endpoint}`, {
        method: "POST",
        headers: {
            ["Content-Type"]: "application/json",
            Authorization: getAuthToken ? `Bearer ${await getAuthToken()}` : "",
            Device: device,
            Version: version,
        },
        body: JSON.stringify(body),
        signal,
    });
    return await processResponse({ response, result });
};
const multipartFetchHttpPost = ({ apiUrl, getAuthToken, device, version }) => async ({ endpoint, body, signal, result }) => {
    const formData = new FormData();
    for (const name in body) {
        formData.append(name, body[name]);
    }
    const response = await fetch(`${apiUrl()}${endpoint}`, {
        method: "POST",
        headers: {
            Authorization: getAuthToken ? `Bearer ${await getAuthToken()}` : "",
            Device: device,
            Version: version,
        },
        body: formData,
        signal,
    });
    return await processResponse({ response, result });
};
const fetchHttpGet = ({ apiUrl, getAuthToken, device, version }) => async ({ endpoint, signal, result }) => {
    const response = await fetch(`${apiUrl()}${endpoint}`, {
        method: "GET",
        credentials: "include",
        headers: {
            ["Content-Type"]: "application/json",
            Authorization: getAuthToken ? `Bearer ${await getAuthToken()}` : "",
            Device: device,
            Version: version,
        },
        referrer: "",
        signal,
    });
    return await processResponse({ response, result });
};
const processResponse = async ({ response, result }) => {
    if (response.ok) {
        const responseText = await response.text();
        const responseResult = responseText ? JSON.parse(responseText) : responseText;
        return (result === null || result === void 0 ? void 0 : result.success) ? result === null || result === void 0 ? void 0 : result.success(responseResult) : responseResult;
    }
    if ((result === null || result === void 0 ? void 0 : result.error) !== undefined) {
        return isErrorFunction(result.error) ? result.error(response) : result.error;
    }
    throw new Error(response.statusText);
};
export { fetchHttpGet, fetchHttpPost, multipartFetchHttpPost };

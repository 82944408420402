/* eslint-disable react/prop-types, react/display-name */
import React, { Fragment, createRef, useCallback, useRef } from "react";
import { FlatList, ScrollView, View } from "react-native";
import { Error, useScreenSize, useScrollTo } from "@lookiero/sty-psp-ui";
import { Layout, QuestionType } from "../../../../../../../projection/question/question.constants";
import { metadataHasPresentationGridLayout, metadataHasPresentationHorizonalLayout, questionHasLayout, } from "../../../../../../../projection/question/question.typeguards";
import { Question } from "../../Question";
import { useAnswerForId } from "../../behaviours/useAnswers";
import { useQuestionValidationError } from "../../behaviours/useQuestionValidationError";
import { style } from "./QuestionLayout.style";
const Grid = ({ level, question, error }) => {
    const screenSize = useScreenSize();
    const numColumns = metadataHasPresentationGridLayout(question.metadata)
        ? question.metadata.layoutColumns[screenSize]
        : 2;
    const childrenQuestionHasLayout = question.children.length > 0 && questionHasLayout(question.children[0]);
    const isFullwidth = screenSize === "L" && numColumns >= 4;
    const gridStyle = !isFullwidth && style.children;
    const renderItem = useCallback(({ item, index }) => (React.createElement(View, { style: [
            { flex: 1 / numColumns },
            index % numColumns !== 0 && (isFullwidth ? style.gridColumnGapLarge : style.gridColumnGap),
            index - numColumns >= 0 && style.gridRowGap,
        ] },
        React.createElement(Question, { level: level + 1, question: item, questionParentId: question.id }))), [isFullwidth, level, numColumns, question.id]);
    return (React.createElement(FlatList, { key: numColumns, bounces: false, contentContainerStyle: [style.grid, error && style.gridError], data: question.children, keyExtractor: ({ id }) => id, numColumns: numColumns, renderItem: ({ item, index }) => renderItem({ item, index }), showsHorizontalScrollIndicator: false, showsVerticalScrollIndicator: false, style: !childrenQuestionHasLayout && gridStyle, testID: "question-layout-grid" }));
};
const HorizontalList = ({ level, question, error }) => {
    const childrenQuestionHasLayout = question.children.length > 0 && questionHasLayout(question.children[0]);
    const { answer } = useAnswerForId({ id: question.id });
    const initialAnswer = useRef(answer);
    const listRef = useRef(null);
    const itemRefs = useRef(question.children.map(() => createRef()));
    const scrollTo = useScrollTo({ scrollRef: listRef });
    const initialScrollDone = useRef(false);
    const handleInitialScroll = useCallback(() => {
        const selectedAnswerIndex = initialAnswer.current && initialAnswer.current.length > 0 && !initialScrollDone.current
            ? question.children.map((child) => child.id).indexOf(initialAnswer.current[0])
            : undefined;
        if (!selectedAnswerIndex) {
            return;
        }
        scrollTo({ ref: itemRefs.current[selectedAnswerIndex], animated: false });
        initialScrollDone.current = true;
    }, [question.children, scrollTo]);
    return (React.createElement(ScrollView, { ref: listRef, bounces: false, showsHorizontalScrollIndicator: false, style: [style.horizontalList, !childrenQuestionHasLayout && style.children], testID: "question-layout-horizontal-list", horizontal: true, onContentSizeChange: handleInitialScroll },
        React.createElement(View, { style: [style.horizontalListContent, error && style.horizontalListContentError] }, question.children.map((item, index) => (React.createElement(View, { key: item.id, ref: itemRefs.current[index], style: [style.horizontalItem, index < question.children.length - 1 && style.horizontalGap] },
            React.createElement(Question, { level: level + 1, question: item, questionParentId: question.id })))))));
};
const HorizontalWrap = ({ level, question, error }) => {
    const isHostRank = question.type === QuestionType.HOST_RANK;
    const renderItem = useCallback(({ item }) => (React.createElement(View, { key: item.id, style: [style.horizontalItem, isHostRank && style.listItemHostRank, style.horizontalWrapGap] },
        React.createElement(Question, { level: level + 1, question: item, questionParentId: question.id }))), [isHostRank, level, question.id]);
    return (React.createElement(View, { style: [style.horizontalWrap, isHostRank && style.hostRank, error && style.horizontalWrapError], testID: "question-layout-horizontal-wrap" }, question.children.map((item, index) => renderItem({ item, index }))));
};
const Horizontal = ({ level, question, error }) => {
    var _a;
    const screenSize = useScreenSize();
    const scrollable = metadataHasPresentationHorizonalLayout(question.metadata) && ((_a = question.metadata.layoutScroll) === null || _a === void 0 ? void 0 : _a[screenSize]);
    const Component = scrollable ? HorizontalList : HorizontalWrap;
    return React.createElement(Component, { error: error, level: level, question: question });
};
const Vertical = ({ level, question, error }) => {
    const hasChildren = question.children.length > 0;
    const isCheckbox = hasChildren && question.children[0].type === QuestionType.CHECKBOX;
    const childrenQuestionHasLayout = hasChildren && questionHasLayout(question.children[0]);
    const renderItem = useCallback(({ item, index }) => (React.createElement(View, { key: item.id, style: index !== 0 && style.verticalGap },
        React.createElement(Question, { level: level + 1, question: item, questionParentId: question.id }),
        isCheckbox && index < question.children.length - 1 && React.createElement(View, { style: [style.separator, style.verticalGap] }))), [isCheckbox, level, question.children.length, question.id]);
    return (React.createElement(View, { style: [!childrenQuestionHasLayout && style.children, error && style.verticalError], testID: "question-layout-vertical" }, question.children.map((item, index) => renderItem({ item, index }))));
};
const LayoutComponent = {
    [Layout.GRID]: Grid,
    [Layout.VERTICAL_LIST]: Vertical,
    [Layout.HORIZONTAL_LIST]: Horizontal,
};
const QuestionLayout = ({ level, question }) => {
    const hasLayout = questionHasLayout(question);
    const Component = hasLayout ? LayoutComponent[question.metadata.layout] : Fragment;
    const ref = useRef(null);
    const validationError = useQuestionValidationError({ questionId: question.id, ref });
    return hasLayout ? (React.createElement(View, { ref: ref },
        React.createElement(Component, { error: Boolean(validationError), level: level, question: question }),
        validationError && React.createElement(Error, { error: validationError, level: 3, style: style.error }))) : (React.createElement(React.Fragment, null, question.children.map((childQuestion) => (React.createElement(Question, { key: childQuestion.id, level: level + 1, question: childQuestion, questionParentId: question.id })))));
};
export { QuestionLayout };

import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
const { borderRadius3, space2, colorBgOpacityLight } = theme();
const style = StyleSheet.create({
    containerBase: {
        borderRadius: borderRadius3,
    },
    inputOption: {
        flex: 1,
        padding: space2,
        position: "absolute",
        right: 0,
        top: 0,
    },
    overlay: {
        backgroundColor: colorBgOpacityLight,
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
    },
});
export { style };

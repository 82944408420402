import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
const { colorTextMedium, space4, space6, space8 } = theme();
const style = StyleSheet.create({
    contentGap: {
        marginTop: space4,
    },
    contentView: {
        flexDirection: "row",
    },
    contentViewColumn: {
        flex: 1,
    },
    contentViewGap: {
        marginLeft: space4,
    },
    description: {
        color: colorTextMedium,
        padding: 0,
    },
    modal: {
        paddingHorizontal: space6,
    },
    modalGap: {
        marginTop: space8,
    },
});
export { style };

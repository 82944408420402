import React, { forwardRef, useCallback, useState } from "react";
import { POINTER, Text } from "@lookiero/aurora";
import { TextInput } from "../textInput/TextInput";
import { style } from "./NumberInput.style";
const NumberInput = forwardRef(({ value, unit, error, testID = "number-input", onBlur, onFocus, ...props }, ref) => {
    const [focused, setFocused] = useState(false);
    const handleOnFocus = useCallback(() => {
        setFocused(true);
        onFocus === null || onFocus === void 0 ? void 0 : onFocus();
    }, [onFocus]);
    const handleOnBlur = useCallback(() => {
        setFocused(false);
        onBlur === null || onBlur === void 0 ? void 0 : onBlur();
    }, [onBlur]);
    return (React.createElement(TextInput, { ...props, ref: ref, keyboardType: "numeric", testID: testID, value: value, onBlur: handleOnBlur, onFocus: handleOnFocus }, unit && (React.createElement(Text, { level: 1, pointerEvents: POINTER.NONE, style: [style.unit, focused && style.unitFocused, error && style.error], detail: true }, unit))));
});
NumberInput.displayName = "NumberInput";
export { NumberInput };

/* eslint-disable react/prop-types */
import React, { useCallback } from "react";
import { View } from "react-native";
import { Text } from "@lookiero/aurora";
import { useIntl } from "@lookiero/i18n-react";
import { AspectRatioView, useMediaImage } from "@lookiero/sty-psp-ui";
import { useAnswerForId } from "../../behaviours/useAnswers";
import { useAnswersValidation } from "../../behaviours/useAnswersValidation";
import { style } from "./ImageQuestionItem.style";
import { OptionImage } from "./OptionImage";
const ImageQuestionItem = ({ question, questionParentId }) => {
    const cdnImageUrl = useMediaImage();
    const resizeMode = question.metadata.resizeMode || "cover";
    const aspectRatio = question.metadata.aspectRatio || 5 / 4;
    const backgroundColor = question.metadata.backgroundColor;
    const { formatMessage } = useIntl();
    const titleText = question.translationKey ? formatMessage({ id: question.translationKey }) : undefined;
    const { answer, onChange } = useAnswerForId({ id: questionParentId });
    const selected = Boolean(answer && answer.find((id) => id === question.id));
    const { validateMaxAnswers } = useAnswersValidation();
    const handleOnPress = useCallback(() => {
        onChange({ questionId: question.id, answer: selected ? undefined : question.id });
        validateMaxAnswers();
    }, [onChange, question.id, selected, validateMaxAnswers]);
    return (React.createElement(View, { style: style.container, testID: "image-question-item" },
        React.createElement(AspectRatioView, { aspectRatio: aspectRatio },
            React.createElement(OptionImage, { checked: selected, imageResizeMode: resizeMode, src: cdnImageUrl({ url: question.metadata.source, width: 400 }), testID: question.id, title: question.translationKey, value: question.translationKey, style: {
                    width: "100%",
                    height: "100%",
                    backgroundColor,
                }, wide: true, onChange: handleOnPress })),
        titleText && (React.createElement(Text, { level: 2, style: style.text, detail: true, ellipsizeMode: true, wide: true }, titleText))));
};
export { ImageQuestionItem };

import { StyleSheet } from "react-native";
import { theme } from "../../../theme/theme";
const { borderWidth1, colorBgBase, fontInputPaddingLeft, inputSize, fontInputHeight, space05, space1 } = theme();
const FIELD_WIDTH = 250;
const FIELD_HEIGHT = inputSize - borderWidth1 * 2;
const INPUT_HEIGHT = fontInputHeight + space05;
const style = StyleSheet.create({
    container: {
        height: FIELD_HEIGHT,
        left: fontInputPaddingLeft,
        position: "absolute",
        top: 1,
        width: "92%",
        zIndex: 1,
    },
    field: {
        position: "absolute",
        zIndex: 1,
    },
    fieldBackground: {
        backgroundColor: colorBgBase,
        height: "100%",
        left: 0,
        position: "absolute",
        width: "100%",
        zIndex: 0,
    },
    fieldText: {
        flexWrap: "nowrap",
        overflow: "hidden",
    },
    fieldTextWrapper: {
        paddingHorizontal: space1,
        zIndex: 1,
    },
    overlay: {
        backgroundColor: colorBgBase,
        height: "100%",
        position: "absolute",
        width: "100%",
        zIndex: 0,
    },
});
export { style, FIELD_WIDTH, FIELD_HEIGHT, INPUT_HEIGHT };

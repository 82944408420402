import React, {createContext, FC, useContext, useMemo} from 'react';
import {nextBestActionRoot} from '../../../bootstrap';
import {sentryRootClient} from '../../../../../errors/sentryRootClient';
import {useViewCustomerAndLocale} from '../../behaviors/useViewCustomerAndLocale';
import {useApp} from '@app/App.context';
import {NextBestActionRootComponent} from '@lookiero/style-profile/dist/src/infrastructure/ui/integration/nextBestAction/NextBestActionRoot';

const StyleProfileNextBestActionContext = createContext<NextBestActionRootComponent>(
  null as unknown as NextBestActionRootComponent,
);

interface StyleProfileNextBestActionHostProps {
  readonly children: JSX.Element;
}

const StyleProfileNextBestActionHost: FC<StyleProfileNextBestActionHostProps> = ({children}) => {
  const {authorization} = useApp();
  const {customer} = useViewCustomerAndLocale();

  const {NextBestActionRootHost, NextBestActionRoot} = useMemo(
    () => nextBestActionRoot({customerId: customer?.customerId, rootClient: sentryRootClient()}),
    [customer],
  );

  if (!authorization) return <>{children}</>;
  if (authorization && !customer) return null;

  return (
    <NextBestActionRootHost>
      <StyleProfileNextBestActionContext.Provider value={NextBestActionRoot}>
        {children}
      </StyleProfileNextBestActionContext.Provider>
    </NextBestActionRootHost>
  );
};

interface UseStyleProfileNextBestActionFunction {
  (): NextBestActionRootComponent;
}

const useStyleProfileNextBestAction: UseStyleProfileNextBestActionFunction = () => {
  const styleProfileNextBestActionContext = useContext(StyleProfileNextBestActionContext);

  if (!styleProfileNextBestActionContext) {
    throw new Error(
      'Your are trying to use the useStyleProfileNextBestAction hook without wrapping your app with the <StyleProfileNextBestActionHost>.',
    );
  }

  return styleProfileNextBestActionContext;
};

export {StyleProfileNextBestActionHost, useStyleProfileNextBestAction};

import { Platform, StyleSheet } from "react-native";
import { theme } from "../../../theme/theme";
const { colorBgBase, shadowColor, elevationLayerL, shadowOffset, shadowOpacityM, shadowRadius, space4, space6 } = theme();
const style = StyleSheet.create({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    sticky: {
        backgroundColor: colorBgBase,
        bottom: 0,
        elevation: elevationLayerL,
        paddingBottom: space6,
        paddingTop: space4,
        shadowColor: shadowColor,
        shadowOffset: shadowOffset,
        shadowOpacity: shadowOpacityM,
        shadowRadius: shadowRadius,
        width: "100%",
        ...Platform.select({
            web: { position: "sticky" },
            ios: { position: "absolute" },
            android: { position: "absolute" },
        }),
        zIndex: 0,
    },
});
export { style };

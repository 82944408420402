import { Platform, StyleSheet } from "react-native";
import { theme } from "../../../theme/theme";
const { borderRadius4, colorBgBase, colorBgOpacity, space6, space8 } = theme();
const modalStylesForColumSize = {
    ["1/4"]: {
        maxWidth: `${100 * (1 / 4)}%`,
    },
    ["1/3"]: {
        maxWidth: `${100 * (1 / 3)}%`,
    },
    ["1/2"]: {
        maxWidth: `${100 * (1 / 2)}%`,
    },
    ["2/3"]: {
        maxWidth: `${100 * (2 / 3)}%`,
    },
    ["1"]: {
        maxWidth: "100%",
    },
};
const style = StyleSheet.create({
    closeButton: {
        padding: 0,
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    container: {
        height: "100%",
        overflow: "hidden",
        ...Platform.select({
            web: { position: "fixed" },
            android: { position: "absolute" },
            ios: { position: "absolute" },
        }),
        width: "100%",
        zIndex: 100,
    },
    header: {
        alignItems: "center",
        flexDirection: "row",
        padding: space6,
    },
    modal: {
        backgroundColor: colorBgBase,
        borderRadius: borderRadius4,
        position: "absolute",
        width: "100%",
        zIndex: 3,
    },
    modalSmall: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
    },
    ...modalStylesForColumSize,
    overlay: {
        backgroundColor: colorBgOpacity,
        ...Platform.select({
            web: { cursor: "pointer" },
        }),
        height: "100%",
        position: "absolute",
        width: "100%",
        zIndex: 1,
    },
    row: {
        alignContent: "center",
        flex: 1,
        justifyContent: "center",
        paddingHorizontal: 0,
    },
    rowSmall: {
        justifyContent: "flex-end",
    },
    safeArea: {
        flex: 1,
        zIndex: 2,
    },
    stickyFooter: {
        paddingHorizontal: space8,
    },
});
export { style };

import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { View } from "react-native";
import invariant from "tiny-invariant";
import { useScrollTo } from "@lookiero/sty-psp-ui";
import { MIN_KEYBOARD_HEIGHT } from "./keyboard";
import { useDetectKeyboardOpen } from "./useDetectKeyboardOpen";
const FocusedInputContext = createContext(null);
export const FocusedInputProvider = ({ children, scrollRef, enabled }) => {
    const scrollTo = useScrollTo({ scrollRef });
    const [focusedInput, setFocusedInput] = useState(null);
    const isKeyboardOpened = useDetectKeyboardOpen();
    const [virtualKeyboardDetected, setVirtualKeyboardDetected] = useState(false);
    const focus = useCallback(({ ref }) => {
        if (!enabled) {
            return;
        }
        setFocusedInput(ref);
    }, [enabled]);
    const blur = useCallback(() => {
        if (!enabled) {
            return;
        }
        setFocusedInput(null);
    }, [enabled]);
    useEffect(() => {
        if (isKeyboardOpened) {
            setVirtualKeyboardDetected(true);
        }
    }, [isKeyboardOpened]);
    useEffect(() => {
        if (!enabled || !focusedInput || virtualKeyboardDetected) {
            return;
        }
        scrollTo({ ref: focusedInput, y: MIN_KEYBOARD_HEIGHT });
    }, [enabled, focusedInput, scrollTo, virtualKeyboardDetected]);
    const value = useMemo(() => ({ focus, blur, isKeyboardOpened: Boolean(focusedInput) }), [blur, focus, focusedInput]);
    return (React.createElement(FocusedInputContext.Provider, { value: value },
        children,
        enabled && focusedInput && !virtualKeyboardDetected && React.createElement(View, { style: { height: MIN_KEYBOARD_HEIGHT } })));
};
const useFocusedInput = () => {
    const focusedInputContext = useContext(FocusedInputContext);
    invariant(focusedInputContext !== null, "Your are trying to use the useFocusedInput hook without wrapping your app with the <FocusedInputProvider>.");
    return focusedInputContext;
};
export { useFocusedInput };

import { PortalProvider } from "@gorhom/portal";
import React from "react";
import { StatusBar } from "react-native";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { Notifications } from "@lookiero/sty-psp-notifications";
import { theme } from "@lookiero/sty-psp-ui";
import { MESSAGING_CONTEXT_ID } from "../../delivery/baseBootstrap";
const { colorBgBase } = theme();
const App = ({ children }) => (React.createElement(SafeAreaProvider, null,
    React.createElement(PortalProvider, { rootHostName: "StyleProfile" },
        React.createElement(StatusBar, { backgroundColor: colorBgBase, barStyle: "dark-content", translucent: true }),
        React.createElement(Notifications, { contextId: MESSAGING_CONTEXT_ID, portalHostName: "StyleProfile" }),
        children)));
export { App };

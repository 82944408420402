/* eslint-disable react/prop-types */
import React, { useCallback } from "react";
import { Pressable } from "react-native";
import { InputOption, Text } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { useAnswerForId } from "../../behaviours/useAnswers";
import { useAnswersValidation } from "../../behaviours/useAnswersValidation";
import { style } from "./CheckboxQuestionItem.style";
const CheckboxQuestionItem = ({ question, questionParentId }) => {
    const titleText = useI18nMessage({ id: question.translationKey });
    const { answer, onChange } = useAnswerForId({ id: questionParentId });
    const selectedAnswer = answer && answer.find((id) => id === question.id);
    const { validateMaxAnswers } = useAnswersValidation();
    const handleOnPress = useCallback(() => {
        onChange({ questionId: question.id, answer: selectedAnswer ? undefined : question.id });
        validateMaxAnswers();
    }, [onChange, question.id, selectedAnswer, validateMaxAnswers]);
    return (React.createElement(Pressable, { accessibilityLabel: "checkbox-question-item", accessibilityState: { checked: Boolean(selectedAnswer) }, style: style.checkbox, testID: "checkbox-question-item", accessible: true, onPress: handleOnPress },
        React.createElement(InputOption, { checked: Boolean(selectedAnswer), name: question.id, value: question.id }),
        React.createElement(Text, { level: 1, style: style.text, detail: true }, titleText)));
};
export { CheckboxQuestionItem };

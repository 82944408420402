/* eslint-disable react/prop-types */
import React, { useCallback } from "react";
import { OptionCard, OptionContainer, SPACE } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { useAnswerForId } from "../../behaviours/useAnswers";
import { useAnswersValidation } from "../../behaviours/useAnswersValidation";
import { QuestionItemState, useQuestionItemState } from "../../behaviours/useQuestionItemState";
import { style } from "./SquaredButtonQuestionItem.style";
const SquaredButtonQuestionItem = ({ question, questionParentId }) => {
    const titleText = useI18nMessage({ id: question.translationKey });
    const { answer, onChange } = useAnswerForId({ id: questionParentId });
    const selectedAnswer = answer && answer.find((id) => id === question.id);
    const { validateMaxAnswers } = useAnswersValidation();
    const state = useQuestionItemState({ questionParentId, question });
    const handleOnChange = useCallback(() => {
        onChange({ questionId: question.id, answer: selectedAnswer ? undefined : question.id });
        validateMaxAnswers();
    }, [onChange, question.id, selectedAnswer, validateMaxAnswers]);
    return (React.createElement(OptionContainer, { gutter: SPACE.SPACE_4, option: selectedAnswer, testID: "squared-button-question-item", onChange: handleOnChange },
        React.createElement(OptionCard, { name: question.translationKey, style: style.button, testID: question.id, title: titleText, unselected: state === QuestionItemState.UNSELECTED, value: question.id, small: true })));
};
export { SquaredButtonQuestionItem };

import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
const { borderRadiusFull, space05, space2, space4 } = theme();
const STRIKE_OUT_BUTTON_WITH_COLOR_HEIGHT = 40;
const COLOR_RADIUS = 14;
const style = StyleSheet.create({
    button: {
        alignItems: "center",
        borderRadius: borderRadiusFull,
        flexDirection: "row",
        height: STRIKE_OUT_BUTTON_WITH_COLOR_HEIGHT,
        paddingHorizontal: space4,
        paddingVertical: space2,
    },
    color: {
        borderRadius: borderRadiusFull,
        height: COLOR_RADIUS,
        marginRight: space2,
        width: COLOR_RADIUS,
    },
    text: {
        paddingTop: space05,
    },
});
export { style };

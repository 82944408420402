import { useCallback } from "react";
import { TrackingEventCategory, useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { TrackingEventName } from "./tracking";
const useTrackNextBestActionClick = ({ country, segment, customerId }) => {
    const emitUserEvent = useEmitUserEvent();
    const trackNextBestActionClick = useCallback(({ slug }) => emitUserEvent({
        event: TrackingEventName.NEXT_BEST_ACTION_CLICK,
        eventCategory: TrackingEventCategory.NAVIGATION,
        section: "myLK_Order",
        store: country,
        segment,
        userId: customerId,
        originNba: slug,
    }), [country, customerId, emitUserEvent, segment]);
    return trackNextBestActionClick;
};
export { useTrackNextBestActionClick };

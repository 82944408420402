import React, { useCallback, useEffect, useRef, useState } from "react";
import { Platform, View } from "react-native";
import { LayoutAnimationConfig, FadeInRight, FadeOutDown, LinearTransition } from "react-native-reanimated";
import { Carousel } from "@lookiero/sty-psp-ui";
import { NextBestActionCard } from "../../nextBestActionCard/NextBestActionCard";
import { style } from "./NextBestActionCarousel.style";
const layoutAnimations = {
    entering: FadeInRight.delay(600).duration(300),
    exiting: FadeOutDown.delay(300).duration(300),
    layout: LinearTransition.delay(450).duration(300),
};
// eslint-disable-next-line react/prop-types
const NextBestActionCarousel = ({ actions = [], onActionTriggered }) => {
    const [carouselIndex, setCarouselIndex] = useState(0);
    const handleOnActiveIndexChanged = useCallback((activeIndex) => setCarouselIndex(activeIndex), []);
    const initialActions = useRef(actions);
    const renderWithLayoutAnimations = Platform.OS !== "web" || initialActions.current !== actions;
    const renderCard = useCallback(({ item }) => (React.createElement(View, { key: item.id, style: style.card },
        React.createElement(NextBestActionCard, { action: item, status: item.status, onActionTriggered: onActionTriggered }))), [onActionTriggered]);
    // Update index if >= actions.length
    useEffect(() => {
        if (carouselIndex >= actions.length) {
            setCarouselIndex(actions.length - 1);
        }
    }, [actions.length, carouselIndex]);
    return (React.createElement(LayoutAnimationConfig, { skipEntering: true, skipExiting: true },
        React.createElement(Carousel, { activeIndex: carouselIndex, data: actions, layoutAnimations: renderWithLayoutAnimations ? layoutAnimations : undefined, style: { container: style.carouselContainer }, onActiveIndexChanged: handleOnActiveIndexChanged }, renderCard)));
};
export { NextBestActionCarousel };

import { questionHasChildren, questionHasDefaultSelection, } from "../../../../../../projection/question/question.typeguards";
import { defaultAnswerForId } from "./defaultAnswerForId";
const mapDefaultAnswers = ({ answers, questions }) => {
    let mappedAnswers = answers;
    const mapAnswers = (question) => {
        if (questionHasChildren(question)) {
            question.children.forEach(mapAnswers);
        }
        if ((!mappedAnswers[question.id] || mappedAnswers[question.id].length === 0) &&
            questionHasDefaultSelection(question)) {
            const defaultAnswer = defaultAnswerForId({ id: question.id, questions });
            if (!defaultAnswer) {
                return;
            }
            mappedAnswers = { ...mappedAnswers, [question.id]: defaultAnswer };
        }
    };
    questions.forEach(mapAnswers);
    return mappedAnswers;
};
export { mapDefaultAnswers };

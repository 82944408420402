import React from "react";
import { MOTION_TIMING, MOTION_TYPES, Motion, PRESSABLE_OPACITY, Text, Touchable, InputOption, Thumbnail, ALIGN, FLEX_DIRECTION, POSITION, SIZE, styles, } from "@lookiero/aurora";
import { style } from "./OptionImage.style";
const OptionImage = ({ checked, children, disabled, height, id, imageResizeMode, name, src, srcSet, title, value, width, onChange, ...others }) => {
    return (React.createElement(Touchable, { ...others, pressableOpacity: PRESSABLE_OPACITY.OPTION_IMAGE, style: styles(style.containerBase, { height, width }, others.style), onPress: !disabled && onChange ? () => onChange(value) : undefined },
        React.createElement(Thumbnail, { height,
            src,
            srcSet,
            resizeMode: imageResizeMode,
            style: styles(others.style),
            width }),
        React.createElement(Text, { hidden: true }, title),
        React.createElement(Motion, { alignItems: ALIGN.CENTER, flexDirection: FLEX_DIRECTION.COLUMN, justifyContent: ALIGN.CENTER, layer: SIZE.XS, position: POSITION.ABSOLUTE, style: style.overlay, timing: MOTION_TIMING.SPRING, type: checked ? MOTION_TYPES.EXPAND : MOTION_TYPES.COLLAPSE, value: { opacity: checked ? 1 : 0 } },
            React.createElement(InputOption, { id: id, name: name, style: style.inputOption, value: value, checked: true })),
        children));
};
OptionImage.displayName = "OptionImage";
export { OptionImage };

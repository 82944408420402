import { SaveFormat, manipulateAsync } from "expo-image-manipulator";
import { useCallback } from "react";
import { Platform } from "react-native";
import { v4 as uuid } from "uuid";
const iOSSafari = () => {
    if (Platform.OS !== "web") {
        return false;
    }
    const userAgent = window.navigator.userAgent || "";
    const iOS = userAgent.match(/iPad/i) || userAgent.match(/iPhone/i);
    const webkit = userAgent.match(/WebKit/i);
    return Boolean(iOS && webkit && !userAgent.match(/CriOS/i));
};
const MAXIMUM_PIXELS = 16777216;
const limitSize = ({ size, maximumPixels = MAXIMUM_PIXELS }) => {
    if (!iOSSafari()) {
        return size;
    }
    const { width, height } = size;
    const requiredPixels = width * height;
    if (requiredPixels <= maximumPixels) {
        return { width, height };
    }
    const scalar = Math.sqrt(maximumPixels) / Math.sqrt(requiredPixels);
    return {
        width: Math.floor(width * scalar),
        height: Math.floor(height * scalar),
    };
};
const loadImageAsync = ({ uri, format }) => new Promise((resolve, reject) => {
    if (!iOSSafari()) {
        resolve(uri);
    }
    const imageSource = new Image();
    imageSource.crossOrigin = "anonymous";
    const canvas = document.createElement("canvas");
    imageSource.onload = () => {
        const size = { height: imageSource.height, width: imageSource.width };
        const { width, height } = limitSize({ size });
        canvas.width = width;
        canvas.height = height;
        const context = canvas.getContext("2d");
        if (!context) {
            reject();
            return;
        }
        context.drawImage(imageSource, 0, 0, width, height);
        resolve(canvas.toDataURL(`image/${format}`));
    };
    imageSource.onerror = () => reject();
    imageSource.src = uri;
});
2;
const getImage = async ({ uri, format }) => {
    if (Platform.OS === "web") {
        // uri is base64 in web Platform
        const result = await fetch(uri);
        return await result.blob();
    }
    return Promise.resolve({
        uri,
        name: uri.replace(/^.*[\\/]/, ""),
        type: `image/${format}`,
    });
};
const useUploadImage = ({ onStart, onSuccess, onError }) => {
    const uploadImage = useCallback(async ({ uri }) => {
        onStart === null || onStart === void 0 ? void 0 : onStart();
        const id = uuid();
        const format = SaveFormat.JPEG;
        try {
            const imageUri = await loadImageAsync({ uri, format });
            const manipulateResult = await manipulateAsync(imageUri, [{ resize: { width: 600 } }], {
                format,
            });
            const image = await getImage({ uri: manipulateResult.uri, format });
            onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess({ id, image });
        }
        catch (error) {
            onError === null || onError === void 0 ? void 0 : onError();
        }
    }, [onError, onStart, onSuccess]);
    return uploadImage;
};
export { useUploadImage };

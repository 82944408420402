/* eslint-disable react/prop-types */
import React from "react";
import { View } from "react-native";
import { Text } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { QuestionTitle } from "../questionTitlte/QuestionTitle";
import { style } from "./HostRankQuestionItem.style";
const HostRankQuestionItem = ({ question, level, children }) => {
    const rankMinDescription = useI18nMessage({ id: question.metadata.minTranslationKey });
    const rankMaxDescription = useI18nMessage({ id: question.metadata.maxTranslationKey });
    return (React.createElement(View, { style: style.container, testID: "host-rank-question-item" },
        React.createElement(QuestionTitle, { level: level, question: question }),
        children,
        React.createElement(View, { style: style.rankDescription },
            React.createElement(Text, { level: 3, style: style.rankDescriptionText, detail: true }, rankMinDescription),
            React.createElement(Text, { level: 3, style: style.rankDescriptionText, detail: true }, rankMaxDescription))));
};
export { HostRankQuestionItem };

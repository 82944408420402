import { StyleSheet, Platform } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
const { borderRadius3, borderRadius5, borderRadiusFull, borderWidth1, colorBorderInputError, colorBgActionPrimaryDisabled, space1, space2, space3, space4, space6, } = theme();
const style = StyleSheet.create({
    children: {
        ...Platform.select({
            web: {
                paddingHorizontal: space6,
            },
            native: {
                marginHorizontal: space6,
            },
        }),
    },
    error: {
        marginHorizontal: space6,
        marginTop: space2,
    },
    errorHorizontalWrap: {
        marginTop: space4,
    },
    // eslint-disable-next-line react-native/no-color-literals
    grid: {
        borderColor: "transparent",
        borderRadius: borderRadius3,
        borderWidth: borderWidth1,
    },
    gridColumnGap: {
        marginLeft: space2,
    },
    gridColumnGapLarge: {
        marginLeft: space6,
    },
    gridError: {
        borderColor: colorBorderInputError,
    },
    gridRowGap: {
        marginTop: space2,
    },
    horizontalGap: {
        paddingRight: space2,
    },
    horizontalItem: {
        flexGrow: 0,
        flexShrink: 0,
    },
    horizontalList: {
        flexGrow: 1,
        flexShrink: 0,
        overflow: "visible",
    },
    horizontalListContent: {
        flexDirection: "row",
    },
    horizontalListContentError: {
        borderColor: colorBorderInputError,
        borderRadius: borderRadiusFull,
        borderWidth: borderWidth1,
    },
    horizontalWrap: {
        flexDirection: "row",
        flexWrap: "wrap",
        marginHorizontal: space6 - space1,
    },
    horizontalWrapError: {
        borderColor: colorBorderInputError,
        borderRadius: borderRadius5,
        borderWidth: borderWidth1,
        marginHorizontal: space6,
        overflow: "hidden",
    },
    horizontalWrapGap: {
        marginHorizontal: space1,
        marginVertical: space1,
    },
    hostRank: {
        justifyContent: "space-between",
    },
    listItemHostRank: {
        height: 56,
    },
    separator: {
        backgroundColor: colorBgActionPrimaryDisabled,
        height: 1,
    },
    verticalError: {
        borderColor: colorBorderInputError,
        borderRadius: 20,
        borderWidth: borderWidth1,
    },
    verticalGap: {
        marginTop: space3,
    },
});
export { style };

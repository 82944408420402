import React, { useCallback } from "react";
import { NOTIFICATION_VARIANT, Notification } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { NotificationLevel } from "../../../../domain/notification/model/notification";
const toastVariantForNotificationLevel = {
    [NotificationLevel.INFO]: NOTIFICATION_VARIANT.NEUTRAL,
    [NotificationLevel.ERROR]: NOTIFICATION_VARIANT.CRITICAL,
    [NotificationLevel.SUCCESS]: NOTIFICATION_VARIANT.SUCCESS,
};
const ToastNotificationItem = ({ notification, onRemove, autoHideTimeout, testID = "toast-notification-item", style: customStyle, ...props }) => {
    const body = useI18nMessage({ id: notification.bodyI18nKey });
    const handleOnClose = useCallback(() => onRemove(notification.id), [notification.id, onRemove]);
    return (React.createElement(Notification, { ...props, motion: undefined, nativeID: testID, style: customStyle, testID: testID, text: body, timeoutClose: autoHideTimeout, variant: toastVariantForNotificationLevel[notification.level], isVisible: true, onClose: handleOnClose }));
};
export { ToastNotificationItem };

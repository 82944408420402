import React, { useCallback, useRef } from "react";
import { View } from "react-native";
import { useI18nMessage } from "@lookiero/i18n-react";
import { TextInput } from "@lookiero/sty-psp-ui";
import { useFocusedInput } from "../../../../../../../hooks/useFocusedInput";
import { I18nMessages } from "../../../../../../../i18n/i18n";
import { EMPTY_DATE, joinDateString, splitDateString } from "../../../../behaviours/dateUtils";
import { style } from "./DateInput.style";
const DATE_SEPARATOR = " / ";
const formatDate = ({ year = "", month = "", day = "" }) => {
    let date = day;
    if (date.length >= 2) {
        date = `${date}${DATE_SEPARATOR}${month}`;
    }
    if (date.length >= 4 + DATE_SEPARATOR.length) {
        date = `${date}${DATE_SEPARATOR}${year}`;
    }
    return date;
};
const DateInput = ({ value, style: customStyle, onFocus, onBlur, onChange }) => {
    const placeholder = useI18nMessage({ id: I18nMessages.HOST_DATE_PLACEHOLDER });
    const { year, month, day } = splitDateString({ date: value });
    const textInputRef = useRef(null);
    const { focus, blur } = useFocusedInput();
    const handleOnBlur = useCallback(() => {
        blur();
        onBlur === null || onBlur === void 0 ? void 0 : onBlur();
    }, [blur, onBlur]);
    const handleOnFocus = useCallback(() => {
        focus({ ref: textInputRef });
        onFocus === null || onFocus === void 0 ? void 0 : onFocus();
    }, [focus, onFocus]);
    const previousTextRef = useRef(formatDate({ day, month, year }));
    const handleOnChange = useCallback((text = "") => {
        const endsWithSeparator = text.endsWith(DATE_SEPARATOR.trim());
        text = text === null || text === void 0 ? void 0 : text.replace(/[^0-9]/g, "");
        if (endsWithSeparator) {
            // Remove last character from text
            text = text.slice(0, -1);
        }
        const [day, month, year] = [text.slice(0, 2), text.slice(2, 4), text.slice(4, 8)];
        previousTextRef.current = formatDate({ day, month, year });
        const date = joinDateString({ day, month, year });
        onChange(date === EMPTY_DATE ? undefined : date);
    }, [onChange]);
    const formattedValue = formatDate({ day, month, year });
    return (React.createElement(View, { accessibilityLabel: "date-input", style: [style.container, customStyle === null || customStyle === void 0 ? void 0 : customStyle.container], testID: "date-input", accessible: true },
        React.createElement(TextInput, { ref: textInputRef, keyboardType: "numeric", maxLength: 8 + DATE_SEPARATOR.length * 2, placeholder: placeholder, testID: "date-input-input", value: formattedValue, style: {
                container: style.inputContainer,
                input: customStyle === null || customStyle === void 0 ? void 0 : customStyle.input,
            }, onBlur: handleOnBlur, onChange: handleOnChange, onFocus: handleOnFocus })));
};
export { DateInput };

import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
const { space1 } = theme();
const style = StyleSheet.create({
    container: {
        flexDirection: "column",
        justifyContent: "flex-start",
    },
    error: {
        marginTop: space1,
    },
});
export { style };

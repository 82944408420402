import React from "react";
import { View } from "react-native";
import { useScreenSize } from "../../../hooks/useScreenSize";
import { style } from "./Row.style";
const Row = ({ children, style: customStyle, ...restProps }) => {
    const screenSize = useScreenSize();
    const screenStyle = screenSize === "L" ? style.rowLarge : screenSize === "M" ? style.rowMedium : style.rowSmall;
    return (React.createElement(View, { style: [style.row, screenStyle, customStyle], ...restProps }, children));
};
export { Row };

import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
const { space1, space4, space6 } = theme();
const style = StyleSheet.create({
    content: {
        flex: 1,
        justifyContent: "space-between",
    },
    contentGap: {
        marginTop: space6,
    },
    contentKeyboard: {
        justifyContent: "flex-start",
    },
    ctaContainer: {
        paddingHorizontal: space6,
    },
    description: {
        marginBottom: space4,
        marginTop: space1,
        paddingHorizontal: space6,
    },
    title: {
        marginBottom: 0,
    },
});
export { style };

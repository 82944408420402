import { questionHasChildren } from "../../../../../../projection/question/question.typeguards";
const questionById = ({ questionId, questions }) => {
    if (!questions) {
        return undefined;
    }
    const question = questions.find((question) => question.id === questionId);
    if (question) {
        return question;
    }
    for (let i = 0; i < questions.length; i++) {
        const currentQuestion = questions[i];
        if (currentQuestion && questionHasChildren(currentQuestion)) {
            const result = questionById({ questionId, questions: currentQuestion.children });
            if (result) {
                return result;
            }
        }
    }
    return undefined;
};
export { questionById };

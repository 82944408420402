import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";
const { borderRadius3, borderRadiusFull, colorBgPrimary, space2, space4 } = theme();
const STRIKE_OUT_BUTTON_WITH_PRINT_HEIGHT = 120;
const PRINT_RADIUS = 40;
const STRIKE_OUT_HEIGHT = 3;
const style = StyleSheet.create({
    button: {
        borderRadius: borderRadius3,
        height: STRIKE_OUT_BUTTON_WITH_PRINT_HEIGHT,
        justifyContent: "space-between",
        padding: space4,
    },
    print: {
        borderRadius: borderRadiusFull,
        height: PRINT_RADIUS,
        width: PRINT_RADIUS,
    },
    strikeOut: {
        backgroundColor: colorBgPrimary,
        borderRadius: borderRadiusFull,
        height: STRIKE_OUT_HEIGHT,
        left: -4,
        position: "absolute",
        top: PRINT_RADIUS / 2,
        transform: [{ rotate: "45deg" }],
        width: PRINT_RADIUS + space2,
    },
    text: {
        padding: 0,
    },
});
export { style };

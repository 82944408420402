import { NOTIFICATION_CREATED } from "../../../../domain/notification/model/notificationCreated";
const fromDomain = ({ aggregateId, type, level, titleI18nKey, bodyI18nKey, acceptI18nKey, }) => ({
    aggregateId,
    type,
    level,
    titleI18nKey,
    bodyI18nKey,
    acceptI18nKey,
});
const isNotificationCreated = (event) => event.name === NOTIFICATION_CREATED;
const storageNotificationsCreate = ({ storage }) => async (aggregateRoot) => {
    const notificationCreated = aggregateRoot.domainEvents.find(isNotificationCreated);
    if (!notificationCreated) {
        return;
    }
    await storage.write(aggregateRoot.aggregateId, fromDomain(aggregateRoot));
};
export { fromDomain, storageNotificationsCreate };

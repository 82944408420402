import { StyleSheet } from "react-native";
import { theme } from "../../../theme/theme";
const { space2 } = theme();
const style = StyleSheet.create({
    buttonIcon: {
        flexGrow: 0,
        padding: space2,
    },
});
export { style };

import React, { createContext, useContext } from "react";
import invariant from "tiny-invariant";
const LoggerContext = createContext(null);
const LoggerProvider = ({ logger, children }) => (React.createElement(LoggerContext.Provider, { value: logger }, children));
const useLogger = () => {
    const logger = useContext(LoggerContext);
    invariant(logger, "Your are trying to use the useLogger hook without wrapping your app with the <LoggerProvider>.");
    return logger;
};
export { LoggerProvider, useLogger };

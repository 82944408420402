import { Platform, StyleSheet } from "react-native";
const style = StyleSheet.create({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    notifications: {
        left: 0,
        ...Platform.select({
            web: { position: "fixed" },
            ios: { position: "absolute" },
            android: { position: "absolute" },
        }),
        flexDirection: "column",
        top: 0,
        width: "100%",
        zIndex: 20,
    },
});
export { style };
